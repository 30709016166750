<!-- ------------------------------------------------------------------------ TEMPLATE START -------------- -->
<template>
  <div>
    <LanguageComponent></LanguageComponent>

    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8">
          <h1 class="mt-7 mb-4">{{ $t("h1ComplaintForm") }}</h1>
          <p
            v-if="!$root.formDisabledForce"
            class="top-info-message mb-7 alert alert-danger"
          >
            {{ $t("topInfoMessage") }}
          </p>
          <p
            v-else-if="$root.existingComplaintData"
            class="top-info-message mb-7 alert alert-danger"
          >
            {{
              $root.existingComplaintData.payloadMessage ||
              $t("topInfoDisabledMessage")
            }}
          </p>
          <p v-else class="top-info-message mb-7 alert alert-danger">
            {{ $t("topInfoDisabledMessage") }}
          </p>
          <p
            v-if="['recipient'].includes($root.appType)"
            class="top-info-message mb-7"
          >
            {{ $t("topInfoMessageRecipient") }}
          </p>
        </div>
        <div class="col-lg-4 d-none d-lg-block d-md-block">
          <img class="img-fluid" alt="" src="/images/damaged-package.png" />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col mb-3">
          <span class="subheader">{{ $t("subheader") }}</span>
        </div>
      </div>

      <form
        id="complaintForm"
        @submit.prevent="onSubmit"
        method="post"
        enctype="multipart/form-data"
        novalidate
      >
        <fieldset id="stepPackageInfo" class="steps">
          <h2 class="mb-5">{{ $t("navPackageInfo") }}</h2>
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="row">
                <div class="col-md-12 col-lg-6">
                  <label for="packageId" class="form-label">
                    {{ $t("navPackageNo") }}
                  </label>
                  <div
                    :class="
                      'input-wrap' +
                      ($root.wasFocused('packageId')
                        ? $root.hasError('packageId')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                  >
                    <div class="input-shape">
                      <input
                        type="text"
                        id="packageId"
                        name="packageId"
                        placeholder="Z 123 4567 890"
                        pattern="Z{1} \d{3} \d{4} \d{3}"
                        required
                        v-model="$root.packageId"
                        :maxlength="maxFullLength"
                        :disabled="true"
                        :class="
                          'form-control' +
                          ($root.wasFocused('packageId')
                            ? $root.hasError('packageId')
                              ? ' is-invalid'
                              : ' is-valid'
                            : '')
                        "
                        @paste="$root.onKeyUp('packageId')"
                        @keyup="$root.onKeyUp('packageId')"
                        @focusin="$root.onFocusIn('packageId')"
                        @focusout="$root.onFocusOutPackageId('packageId')"
                      />
                    </div>
                  </div>
                  <InputErrorComponent
                    field="packageId"
                    :errors="$root.errors"
                  />
                </div>

                <div
                  class="col-md-12 col-lg-6"
                  v-if="['client', 'recipient'].includes($root.appType)"
                >
                  <label for="insuranceValue" class="form-label">
                    {{ $t("navPackageValue") }}
                  </label>
                  <div
                    :class="
                      'input-wrap' +
                      ($root.wasFocused('insuranceValue')
                        ? $root.hasError('insuranceValue')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                  >
                    <div class="input-shape">
                      <input
                        type="text"
                        id="insuranceValue"
                        name="insuranceValue"
                        v-model="$root.insuranceValue"
                        :disabled="true || $root.formDisabledForce"
                        :class="
                          'form-control' +
                          ($root.wasFocused('insuranceValue')
                            ? $root.hasError('insuranceValue')
                              ? ' is-invalid'
                              : ' is-valid'
                            : '')
                        "
                        @keyup="$root.onKeyUp('insuranceValue')"
                        @focusin="$root.onFocusIn('insuranceValue')"
                        @focusout="$root.onFocusOut('insuranceValue')"
                      />
                    </div>
                  </div>
                  <InputErrorComponent
                    field="insuranceValue"
                    :errors="$root.errors"
                  />
                </div>

                <div class="col-md-12 col-lg-6" v-if="$root.isRequireBankInfo">
                  <label for="bankValue" class="form-label">
                    {{ $t("solemnlyDeclareThatValueIs") }} *
                  </label>
                  <div
                    :class="
                      'input-wrap' +
                      ($root.wasFocused('bankValue')
                        ? $root.hasError('bankValue')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                  >
                    <div class="input-shape">
                      <span class="input-group-text">
                        {{ $root.insuranceCurrency }}
                      </span>
                      <input
                        type="text"
                        id="bankValue"
                        name="bankValue"
                        v-model="$root.bankValue"
                        :disabled="$root.formDisabledForce"
                        :class="
                          'form-control input-group-input fit-content' +
                          ($root.wasFocused('bankValue')
                            ? $root.hasError('bankValue')
                              ? ' is-invalid'
                              : ' is-valid'
                            : '')
                        "
                        @keyup="$root.onKeyUp('bankValue')"
                        @focusin="$root.onFocusIn('bankValue')"
                        @focusout="$root.onFocusOut('bankValue')"
                      />
                    </div>
                  </div>
                  <InputErrorComponent
                    field="bankValue"
                    :errors="$root.errors"
                  />
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset
          id="stepBankAccount"
          class="steps"
          v-if="$root.isRequireBankInfo"
        >
          <h2 class="mb-5">{{ $t("bankAccount") }}</h2>
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <label for="bankBankAccount" class="form-label">
                {{ $t("bankAccountToSend") }} *
              </label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('bankBankAccount')
                    ? $root.hasError('bankBankAccount')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <input
                    type="text"
                    id="bankBankAccount"
                    name="bankBankAccount"
                    v-model="$root.bankBankAccount"
                    :disabled="$root.formDisabledForce"
                    :class="
                      'form-control' +
                      ($root.wasFocused('bankBankAccount')
                        ? $root.hasError('bankBankAccount')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    @keyup="$root.onKeyUp('bankBankAccount')"
                    @focusin="$root.onFocusIn('bankBankAccount')"
                    @focusout="$root.onFocusOut('bankBankAccount')"
                  />
                </div>
              </div>
              <InputErrorComponent
                field="bankBankAccount"
                :errors="$root.errors"
              />
            </div>

            <div class="col-md-12 col-lg-4" v-if="$root.isRequireBankInfo">
              <label for="bankCountry" class="form-label">
                {{ $t("bankAccountCountry") }} *
              </label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('bankCountry')
                    ? $root.hasError('bankCountry')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <select
                    type="text"
                    id="bankCountry"
                    name="bankCountry"
                    v-model="$root.bankCountry"
                    :disabled="$root.formDisabledForce"
                    :class="
                      'form-control' +
                      ($root.wasFocused('bankCountry')
                        ? $root.hasError('bankCountry')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    @keyup="$root.onKeyUp('bankCountry')"
                    @focusin="$root.onFocusIn('bankCountry')"
                    @focusout="$root.onFocusOut('bankCountry')"
                  >
                    <option
                      v-for="countryItem in $root.enums.bankCountries"
                      :value="countryItem.id"
                      :key="countryItem.id"
                      :selected="$root.bankCountry === countryItem.id"
                    >
                      {{ countryItem.label }}
                    </option>
                  </select>
                </div>
              </div>
              <InputErrorComponent field="bankCountry" :errors="$root.errors" />
            </div>
          </div>
        </fieldset>

        <fieldset id="stepPerson" class="steps">
          <h2 class="mb-5">{{ $t("navPerson") }}</h2>
          <div
            class="row"
            v-if="
              ['recipient', 'mobile'].includes($root.appType) &&
              $root.email !== ''
            "
          >
            <div class="col-md-12 mb-4">
              <div class="input-radio">
                <input
                  type="radio"
                  id="personFromApi"
                  name="personFrom"
                  :value="$root.enums.personFrom.api"
                  class="form-check-input"
                  required
                  v-model="$root.personFrom"
                  @focusin="$root.onFocusIn('personFrom')"
                  @focusout="$root.onFocusOut('personFrom')"
                  @change="$root.onFocusOut('personFrom')"
                />
                <label class="form-label" for="personFromApi">
                  {{
                    ["recipient"].includes($root.appType)
                      ? $t("personIdenticalToRecipient")
                      : $t("personIdenticalToSender")
                  }}
                </label>
              </div>
              <div class="input-radio">
                <input
                  type="radio"
                  id="personFromForm"
                  name="personFrom"
                  :value="$root.enums.personFrom.form"
                  class="form-check-input"
                  required
                  v-model="$root.personFrom"
                  @focusin="$root.onFocusIn('personFrom')"
                  @focusout="$root.onFocusOut('personFrom')"
                  @change="$root.onFocusOut('personFrom')"
                />
                <label class="form-label" for="personFromForm">
                  {{ $t("personDifferent") }}
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="col-md-12 col-lg-4"
              v-if="
                !(
                  ['recipient', 'mobile'].includes($root.appType) &&
                  $root.email !== ''
                ) ||
                $root.personFrom === $root.enums.personFrom.form ||
                !$root.baseData ||
                $root.baseData.fullName === null
              "
            >
              <label for="fullName" class="form-label">
                {{ $t("personLabelFullname") }} *
              </label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('fullName')
                    ? $root.hasError('fullName')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    v-model="$root.fullName"
                    required
                    autocomplete="new-password"
                    :disabled="
                      $root.appType === 'mobile' ||
                      ($root.appType === 'client' &&
                        $root.packetServiceType !== 'mk2c') ||
                      $root.formDisabledForce
                    "
                    :class="
                      'form-control' +
                      ($root.wasFocused('fullName')
                        ? $root.hasError('fullName')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    @keyup="$root.onKeyUp('fullName')"
                    @focusin="$root.onFocusIn('fullName')"
                    @focusout="$root.onFocusOut('fullName')"
                  />
                </div>
              </div>
              <InputErrorComponent field="fullName" :errors="$root.errors" />
            </div>
            <div
              class="col-md-12 col-lg-4"
              v-if="
                !(
                  ['recipient', 'mobile'].includes($root.appType) &&
                  $root.email !== ''
                ) || $root.personFrom === $root.enums.personFrom.form
              "
            >
              <label for="phone" class="form-label">
                {{ $t("personLabelPhone") }} *
              </label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('phone')
                    ? $root.hasError('phone')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <span
                    v-if="
                      $root.phonePrefix &&
                      ((['recipient', 'mobile'].includes($root.appType) &&
                        $root.email !== '') ||
                        $root.formDisabledForce)
                    "
                    class="input-group-text"
                    >+{{ $root.phonePrefix }}</span
                  >
                  <select
                    v-if="
                      $root.appType === 'client' && !$root.formDisabledForce
                    "
                    class="form-control input-group-select input-group-select--prefix"
                    required
                    name="phonePrefix"
                    id="phonePrefix"
                    aria-label="form-select"
                    v-model="$root.phonePrefix"
                    @keyup="$root.onKeyUp('phonePrefix')"
                    @focusin="$root.onFocusIn('phonePrefix')"
                    @focusout="$root.onFocusOut('phonePrefix')"
                    @change="$root.onFocusOut('phonePrefix')"
                  >
                    <option
                      v-for="prefix in $root.enums.phonePrefixes"
                      :key="prefix"
                      :value="prefix"
                      :selected="$root.phonePrefix == prefix"
                    >
                      +{{ prefix }}
                    </option>
                  </select>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    v-model="$root.phone"
                    required
                    autocomplete="new-password"
                    :class="
                      'form-control' +
                      ($root.appType === 'client' && !$root.formDisabledForce
                        ? ' input-group-input'
                        : '') +
                      ($root.wasFocused('phone')
                        ? $root.hasError('phone')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    :disabled="
                      ['recipient', 'mobile'].includes($root.appType) ||
                      $root.formDisabledForce
                    "
                    @keyup="$root.onKeyUp('phone')"
                    @focusin="$root.onFocusIn('phone')"
                    @focusout="$root.onFocusOut('phone')"
                  />
                </div>
              </div>
              <InputErrorComponent field="phone" :errors="$root.errors" />
            </div>
          </div>
          <div
            class="row"
            v-if="
              !(
                ['recipient', 'mobile'].includes($root.appType) &&
                $root.email !== ''
              ) ||
              $root.personFrom === $root.enums.personFrom.form ||
              !$root.baseData ||
              $root.baseData.email === null
            "
          >
            <div class="col-md-12 col-lg-4">
              <label for="email" class="form-label">
                {{ $t("personLabelEmail") }} *
              </label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('email')
                    ? $root.hasError('email')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    v-model="$root.email"
                    required
                    autocomplete="new-password"
                    :disabled="$root.formDisabledForce"
                    :class="
                      'form-control' +
                      ($root.wasFocused('email')
                        ? $root.hasError('email')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    @paste="$root.onKeyUpEmail('email')"
                    @keyup="$root.onKeyUpEmail('email')"
                    @focusin="$root.onFocusIn('email')"
                    @focusout="$root.onFocusOutEmail('email')"
                  />
                </div>
              </div>
              <InputErrorComponent field="email" :errors="$root.errors" />
            </div>
            <div class="col-md-12 col-lg-4">
              <label for="emailConfirmation" class="form-label">
                {{ $t("personLabelEmailConfirmation ") }} *
              </label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('emailConfirmation')
                    ? $root.hasError('emailConfirmation')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <input
                    type="email"
                    id="emailConfirmation"
                    name="emailConfirmation"
                    v-model="$root.emailConfirmation"
                    required
                    autocomplete="new-password"
                    :disabled="$root.formDisabledForce"
                    :class="
                      'form-control' +
                      ($root.wasFocused('emailConfirmation')
                        ? $root.hasError('emailConfirmation')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    @paste="$root.onKeyUpEmailConfirmation('emailConfirmation')"
                    @keyup="$root.onKeyUpEmailConfirmation('emailConfirmation')"
                    @focusin="$root.onFocusIn('emailConfirmation')"
                    @focusout="
                      $root.onFocusOutEmailConfirmation('emailConfirmation')
                    "
                  />
                </div>
              </div>
              <InputErrorComponent
                field="emailConfirmation"
                :errors="$root.errors"
              />
            </div>
          </div>

          <div class="row">
            <div
              v-if="['client'].includes($root.appType)"
              class="col mt-minus-3"
            >
              <a
                :href="$root.termsAndConditionsLinks[$root.country].general"
                :title="$t('generalTermsAndConditions')"
                target="_blank"
              >
                {{ $t("generalTermsAndConditions") }}
              </a>
            </div>

            <div
              v-if="
                ['mobile'].includes($root.appType) &&
                ['cz', 'sk', 'hu'].includes($root.country)
              "
              class="col mt-minus-3"
            >
              <a
                :href="
                  $root.termsAndConditionsLinks[$root.country].generalMobile
                "
                :title="$t('generalTermsAndConditionsMobile')"
                target="_blank"
              >
                {{ $t("generalTermsAndConditionsMobile") }}
              </a>
            </div>
          </div>
        </fieldset>

        <fieldset id="stepClaimSubject" class="steps">
          <h2 class="mb-5">{{ $t("navClaimSubject") }}</h2>
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <label for="reason" class="form-label">
                {{ $t("labelComplaintType") }} *
              </label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('reason')
                    ? $root.hasError('reason')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape padding-select-disabled">
                  <select
                    id="reason"
                    name="reason"
                    aria-label="form-select"
                    v-model="$root.reason"
                    required
                    :disabled="true"
                    :class="
                      'form-select' +
                      ($root.wasFocused('reason')
                        ? $root.hasError('reason')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    @keyup="$root.onKeyUp('reason')"
                    @focusin="$root.onFocusIn('reason')"
                    @focusout="$root.onFocusOut('reason')"
                    @change="$root.onFocusOut('reason')"
                  >
                    <option hidden disabled selected value>
                      {{ $t("notSelected") }}
                    </option>
                    <template v-for="(item, index) in $root.enums.reasons">
                      <option
                        :key="index + '1'"
                        :value="item.id"
                        v-if="$root.isShowReasonDelay && item.id === 'delay'"
                      >
                        {{ item.label }}
                      </option>
                      <option
                        :key="index + '2'"
                        :value="item.id"
                        v-else-if="item.id !== 'delay'"
                      >
                        {{ item.label }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
              <InputErrorComponent field="reason" :errors="$root.errors" />
            </div>
          </div>

          <div v-if="['substitution'].includes($root.reason)">
            <div class="row">
              <div class="col-md-12 col-lg-4 mt-minus-3 mb-5">
                <label for="reasonBarcode" class="form-label">
                  {{ $t("reasonSubstitutionWith") }}
                </label>
                <div
                  :class="
                    'input-wrap' +
                    ($root.wasFocused('reasonBarcode')
                      ? $root.hasError('reasonBarcode')
                        ? ' is-invalid'
                        : ' is-valid'
                      : '')
                  "
                >
                  <div class="input-shape">
                    <input
                      type="text"
                      id="reasonBarcode"
                      name="reasonBarcode"
                      placeholder="Z 123 4567 890"
                      pattern="Z{1} \d{3} \d{4} \d{3}"
                      v-model="$root.reasonBarcode"
                      autocomplete="new-password"
                      :maxlength="maxFullLength"
                      :disabled="$root.formDisabledForce"
                      :class="
                        'form-control' +
                        ($root.wasFocused('reasonBarcode')
                          ? $root.hasError('reasonBarcode')
                            ? ' is-invalid'
                            : ' is-valid'
                          : '')
                      "
                      @keyup="$root.onKeyUp('reasonBarcode')"
                      @paste="$root.onKeyUp('reasonBarcode')"
                      @focusin="$root.onFocusIn('reasonBarcode')"
                      @focusout="$root.onFocusOut('reasonBarcode')"
                    />
                  </div>
                </div>
                <InputErrorComponent
                  field="reasonBarcode"
                  :errors="$root.errors"
                />
              </div>
            </div>
          </div>

          <template
            v-if="
              ['delay'].includes($root.reason) &&
              $root.isDelivered &&
              $root.isLocalDelivery
            "
          >
            <div class="row">
              <div class="col-md-12 col-lg-4 mt-minus-3">
                <div class="input-radio">
                  <input
                    type="radio"
                    value="less_7_days"
                    id="reasonDelayDetailSevenLess"
                    name="reasonDetail"
                    checked
                    v-model="$root.reasonDetail"
                    class="form-check-input"
                    autocomplete="new-password"
                    :disabled="$root.formDisabledForce"
                    @keyup="$root.onKeyUp('reasonDetail')"
                    @focusin="$root.onFocusIn('reasonDetail')"
                    @focusout="$root.onFocusOut('reasonDetail')"
                    @change="$root.onFocusOut('reasonDetail')"
                  />
                  <label
                    class="form-check-label"
                    for="reasonDelayDetailSevenLess"
                  >
                    {{
                      $root.enums.reasonDetails.find(
                        (x) => x.id === "less_7_days",
                      ).label
                    }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-4 mb-5">
                <div class="input-radio">
                  <input
                    type="radio"
                    value="more_7_days"
                    id="reasonDelayDetailNationalSevenMore"
                    name="reasonDetail"
                    v-model="$root.reasonDetail"
                    class="form-check-input"
                    autocomplete="new-password"
                    :disabled="$root.formDisabledForce"
                    @keyup="$root.onKeyUp('reasonDetail')"
                    @focusin="$root.onFocusIn('reasonDetail')"
                    @focusout="$root.onFocusOut('reasonDetail')"
                    @change="$root.onFocusOut('reasonDetail')"
                  />
                  <label
                    class="form-check-label"
                    for="reasonDelayDetailNationalSevenMore"
                  >
                    {{
                      $root.enums.reasonDetails.find(
                        (x) => x.id === "more_7_days",
                      ).label
                    }}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div
            v-else-if="
              (['delay'].includes($root.reason) &&
                $root.isDelivered &&
                !$root.isLocalDelivery) ||
              (['not_paid_cod'].includes($root.reason) &&
                ['client', 'mobile'].includes($root.appType))
            "
          >
            <div class="row">
              <div class="col-md-12 col-lg-4 mt-minus-3">
                <div class="input-radio">
                  <input
                    type="radio"
                    value="less_10_days"
                    id="reasonDelayDetailTenLess"
                    name="reasonDetail"
                    checked
                    v-model="$root.reasonDetail"
                    class="form-check-input"
                    :disabled="$root.formDisabledForce"
                    @keyup="$root.onKeyUp('reasonDetail')"
                    @focusin="$root.onFocusIn('reasonDetail')"
                    @focusout="$root.onFocusOut('reasonDetail')"
                    @change="$root.onFocusOut('reasonDetail')"
                  />
                  <label
                    class="form-check-label"
                    for="reasonDelayDetailTenLess"
                  >
                    {{
                      $root.enums.reasonDetails.find(
                        (x) => x.id === "less_10_days",
                      ).label
                    }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-4 mb-5">
                <div class="input-radio">
                  <input
                    type="radio"
                    value="more_10_days"
                    id="reasonDelayDetailForeignTenMore"
                    name="reasonDetail"
                    v-model="$root.reasonDetail"
                    class="form-check-input"
                    :disabled="$root.formDisabledForce"
                    @keyup="$root.onKeyUp('reasonDetail')"
                    @focusin="$root.onFocusIn('reasonDetail')"
                    @focusout="$root.onFocusOut('reasonDetail')"
                    @change="$root.onFocusOut('reasonDetail')"
                  />
                  <label
                    class="form-check-label"
                    for="reasonDelayDetailForeignTenMore"
                  >
                    {{
                      $root.enums.reasonDetails.find(
                        (x) => x.id === "more_10_days",
                      ).label
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="
              $root.reason &&
              ['damage', 'missing_content'].includes($root.reason)
            "
          >
            <div class="row">
              <div class="col-md-12 col-lg-4">
                <label for="packageType" class="form-label">
                  {{ $t("labelPackageType") }} *
                </label>
                <div
                  :class="
                    'input-wrap' +
                    ($root.wasFocused('packageType')
                      ? $root.hasError('packageType')
                        ? ' is-invalid'
                        : ' is-valid'
                      : '')
                  "
                >
                  <div class="input-shape">
                    <select
                      id="packageType"
                      name="packageType"
                      aria-label="form-select"
                      v-model="$root.packageType"
                      required
                      :disabled="$root.formDisabledForce"
                      :class="
                        'form-select' +
                        ($root.wasFocused('packageType')
                          ? $root.hasError('packageType')
                            ? ' is-invalid'
                            : ' is-valid'
                          : '')
                      "
                      @keyup="$root.onKeyUp('packageType')"
                      @focusin="$root.onFocusIn('packageType')"
                      @focusout="$root.onFocusOut('packageType')"
                      @change="$root.onFocusOut('packageType')"
                    >
                      <option hidden disabled selected value>
                        {{ $t("notSelected") }}
                      </option>
                      <option
                        v-for="(item, index) in $root.enums.packageTypes"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <InputErrorComponent
                  field="packageType"
                  :errors="$root.errors"
                />
              </div>

              <div
                v-if="['other'].includes($root.packageType)"
                class="col-md-12 col-lg-8"
              >
                <label for="packageTypeOther" class="form-label">&nbsp;</label>
                <div
                  :class="
                    'input-wrap' +
                    ($root.wasFocused('packageTypeOther')
                      ? $root.hasError('packageTypeOther')
                        ? ' is-invalid'
                        : ' is-valid'
                      : '')
                  "
                >
                  <div class="input-shape input-shape-textarea">
                    <textarea
                      id="packageTypeOther"
                      name="packageTypeOther"
                      v-model="$root.packageTypeOther"
                      required
                      :disabled="$root.formDisabledForce"
                      :placeholder="$t('placeholderPackageTypeOther')"
                      :class="
                        'form-control' +
                        ($root.wasFocused('packageTypeOther')
                          ? $root.hasError('packageTypeOther')
                            ? ' is-invalid'
                            : ' is-valid'
                          : '')
                      "
                      @keyup="$root.onKeyUp('packageTypeOther')"
                      @focusin="$root.onFocusIn('packageTypeOther')"
                      @focusout="$root.onFocusOut('packageTypeOther')"
                    />
                  </div>
                </div>
                <InputErrorComponent
                  field="packageTypeOther"
                  :errors="$root.errors"
                />
              </div>
            </div>
          </div>

          <div
            v-if="
              $root.reason &&
              ['damage', 'missing_content'].includes($root.reason)
            "
          >
            <div class="row">
              <div class="col-md-12 col-lg-4">
                <label for="packageLining" class="form-label">
                  {{ $t("labelPackageLining") }} *
                </label>
                <div
                  :class="
                    'input-wrap' +
                    ($root.wasFocused('packageLining')
                      ? $root.hasError('packageLining')
                        ? ' is-invalid'
                        : ' is-valid'
                      : '')
                  "
                >
                  <div class="input-shape">
                    <select
                      id="packageLining"
                      name="packageLining"
                      aria-label="form-select"
                      v-model="$root.packageLining"
                      required
                      :disabled="$root.formDisabledForce"
                      :class="
                        'form-select' +
                        ($root.wasFocused('packageLining')
                          ? $root.hasError('packageLining')
                            ? ' is-invalid'
                            : ' is-valid'
                          : '')
                      "
                      @keyup="$root.onKeyUp('packageLining')"
                      @focusin="$root.onFocusIn('packageLining')"
                      @focusout="$root.onFocusOut('packageLining')"
                      @change="$root.onFocusOut('packageLining')"
                    >
                      <option hidden disabled selected value>
                        {{ $t("notSelected") }}
                      </option>
                      <option
                        v-for="(item, index) in $root.enums.packageLinings"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <InputErrorComponent
                  field="packageLining"
                  :errors="$root.errors"
                />
              </div>

              <div
                v-if="['other'].includes($root.packageLining)"
                class="col-md-12 col-lg-8"
              >
                <label for="packageLiningOther" class="form-label"
                  >&nbsp;</label
                >
                <div
                  :class="
                    'input-wrap' +
                    ($root.wasFocused('packageLiningOther')
                      ? $root.hasError('packageLiningOther')
                        ? ' is-invalid'
                        : ' is-valid'
                      : '')
                  "
                >
                  <div class="input-shape input-shape-textarea">
                    <textarea
                      id="packageLiningOther"
                      name="packageLiningOther"
                      v-model="$root.packageLiningOther"
                      required
                      :disabled="$root.formDisabledForce"
                      :placeholder="$t('placeholderPackingLiningOther')"
                      :class="
                        'form-control' +
                        ($root.wasFocused('packageLiningOther')
                          ? $root.hasError('packageLiningOther')
                            ? ' is-invalid'
                            : ' is-valid'
                          : '')
                      "
                      @keyup="$root.onKeyUp('packageLiningOther')"
                      @focusin="$root.onFocusIn('packageLiningOther')"
                      @focusout="$root.onFocusOut('packageLiningOther')"
                    ></textarea>
                  </div>
                </div>
                <InputErrorComponent
                  field="packageLiningOther"
                  :errors="$root.errors"
                />
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset
          v-if="
            $root.reason &&
            [
              'loss',
              'damage',
              'missing_content',
              'substitution',
              'delay',
            ].includes($root.reason)
          "
          id="stepPackageContentAdditionalInfo"
          class="steps"
        >
          <h2 class="mb-5">
            {{
              ["substitution"].includes($root.reason)
                ? $t("navPackageContentAdditionalInfoUndelivered")
                : $t("navPackageContentAdditionalInfo")
            }}
          </h2>
          <div class="row">
            <div
              v-if="
                $root.reason &&
                [
                  'loss',
                  'damage',
                  'missing_content',
                  'delay',
                  'incorrect_billing',
                ].includes($root.reason)
              "
              class="col-md-12 col-lg-4"
            >
              <label for="itemCount" class="form-label">
                {{ $t("contentTotalPackageItemsCount") }} *
              </label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('itemCount')
                    ? $root.hasError('itemCount')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <input
                    type="number"
                    min="1"
                    :max="maxClaimedItems"
                    id="itemCount"
                    name="itemCount"
                    required
                    v-model="$root.itemCount"
                    :disabled="$root.formDisabledForce"
                    :class="
                      'form-control' +
                      ($root.wasFocused('itemCount')
                        ? $root.hasError('itemCount')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    @paste="$root.onKeyUpNumber('itemCount')"
                    @keyup="$root.onKeyUpNumber('itemCount')"
                    @keydown="$root.onKeyDownNumber('itemCount')"
                    @focusin="$root.onFocusIn('itemCount')"
                    @focusout="$root.onFocusOut('itemCount')"
                  />
                </div>
              </div>
              <InputErrorComponent field="itemCount" :errors="$root.errors" />
            </div>
            <div
              v-if="
                $root.reason &&
                [
                  'loss',
                  'damage',
                  'missing_content',
                  'substitution',
                  'incorrect_billing',
                ].includes($root.reason)
              "
              class="col-md-12 col-lg-4"
            >
              <label for="claimedItemCount" class="form-label">
                {{
                  $root.reason && ["substitution"].includes($root.reason)
                    ? $t("contentTotalPackageItemsCount")
                    : $t("contentClaimedPackageItemsCount")
                }}
                *
              </label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('claimedItemCount')
                    ? $root.hasError('claimedItemCount')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <input
                    type="number"
                    min="1"
                    :max="maxClaimedItems"
                    id="claimedItemCount"
                    name="claimedItemCount"
                    v-model="$root.claimedItemCount"
                    :disabled="$root.formDisabledForce"
                    :class="
                      'form-control' +
                      ($root.wasFocused('claimedItemCount')
                        ? $root.hasError('claimedItemCount')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    @paste="$root.onKeyUpNumber('claimedItemCount')"
                    @keyup="$root.onKeyUpNumber('claimedItemCount')"
                    @keydown="$root.onKeyDownNumber('claimedItemCount')"
                    @focusin="$root.onFocusIn('claimedItemCount')"
                    @focusout="$root.onFocusOut('claimedItemCount')"
                  />
                </div>
              </div>
              <InputErrorComponent
                field="claimedItemCount"
                :errors="$root.errors"
              />
            </div>
          </div>

          <div
            v-if="
              $root.reason &&
              [
                'loss',
                'damage',
                'missing_content',
                'substitution',
                'incorrect_billing',
              ].includes($root.reason)
            "
            class="row"
          >
            <template
              v-for="index in Array.from({
                length: $root.claimedItemCount,
              }).map((u, i) => i)"
              :key="index"
            >
              <div class="col-md-12 col-lg-4">
                <label
                  :for="'claimedPackageItemsLabel_' + index"
                  class="form-label"
                >
                  {{ $t("contentClaimedPackageItemLabel") }} *
                </label>
                <div
                  :class="
                    'input-wrap' +
                    ($root.wasFocused('claimedPackageItemsLabel_' + index)
                      ? !$root.hasValue('claimedPackageItemsLabel_' + index)
                        ? ' is-invalid'
                        : ' is-valid'
                      : '')
                  "
                >
                  <div class="input-shape">
                    <input
                      type="text"
                      :id="'claimedPackageItemsLabel_' + index"
                      name="claimedPackageItemsLabel[]"
                      required
                      :disabled="$root.formDisabledForce"
                      :class="
                        'form-control custom-mandatory-validation' +
                        ($root.wasFocused('claimedPackageItemsLabel_' + index)
                          ? !$root.hasValue('claimedPackageItemsLabel_' + index)
                            ? ' is-invalid'
                            : ' is-valid'
                          : '')
                      "
                      @keyup="
                        $root.onKeyUp('claimedPackageItemsLabel_' + index)
                      "
                      @focusin="
                        $root.onFocusIn('claimedPackageItemsLabel_' + index)
                      "
                      @focusout="
                        $root.onFocusOut('claimedPackageItemsLabel_' + index)
                      "
                    />
                  </div>
                </div>
                <InputErrorComponent
                  :field="'claimedPackageItemsLabel_' + index"
                  :errors="$root.errors"
                />
              </div>

              <div class="col-md-12 col-lg-4">
                <label
                  :for="'claimedPackageItemsUrls_' + index"
                  class="form-label"
                >
                  {{ $t("contentClaimedPackageItemUrl") }}
                </label>
                <div class="input-wrap">
                  <div class="input-shape">
                    <input
                      type="text"
                      :id="'claimedPackageItemsUrls_' + index"
                      :disabled="$root.formDisabledForce"
                      name="claimedPackageItemsUrls[]"
                      class="form-control"
                      autocomplete="new-password"
                    />
                  </div>
                </div>
              </div>

              <div
                v-if="
                  $root.reason &&
                  [
                    'loss',
                    'missing_content',
                    'substitution',
                    'delay',
                    'incorrect_billing',
                    'not_paid_cod',
                  ].includes($root.reason)
                "
                class="col-md-12 col-lg-4"
              >
                <label
                  :for="'claimedPackageItemsDescriptions_' + index"
                  class="form-label"
                >
                  {{ $t("contentClaimedPackageItemDescription") }}
                </label>
                <div class="input-wrap">
                  <div class="input-shape">
                    <input
                      type="text"
                      :id="'claimedPackageItemsDescriptions_' + index"
                      :disabled="$root.formDisabledForce"
                      name="claimedPackageItemsDescriptions[]"
                      class="form-control"
                      autocomplete="new-password"
                    />
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </template>
          </div>
        </fieldset>

        <fieldset
          v-if="$root.reason && ['substitution'].includes($root.reason)"
          id="stepPackageContentAdditionalInfoWrongPacketContent"
          class="steps"
        >
          <h2 class="mb-5">
            {{ $t("navPackageContentAdditionalInfoWrongPacketContent") }}
          </h2>
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <label
                for="wrongPacketContentClaimedItemCount"
                class="form-label"
              >
                {{ $t("contentTotalPackageItemsCount") }} *
              </label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('wrongPacketContentClaimedItemCount')
                    ? $root.hasError('wrongPacketContentClaimedItemCount')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <input
                    type="number"
                    min="1"
                    :max="maxClaimedItems"
                    id="wrongPacketContentClaimedItemCount"
                    name="wrongPacketContentClaimedItemCount"
                    required
                    v-model="$root.wrongPacketContentClaimedItemCount"
                    :disabled="$root.formDisabledForce"
                    :class="
                      'form-control' +
                      ($root.wasFocused('wrongPacketContentClaimedItemCount')
                        ? $root.hasError('wrongPacketContentClaimedItemCount')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    @paste="
                      $root.onKeyUpNumber('wrongPacketContentClaimedItemCount')
                    "
                    @keyup="
                      $root.onKeyUpNumber('wrongPacketContentClaimedItemCount')
                    "
                    @keydown="
                      $root.onKeyDownNumber(
                        'wrongPacketContentClaimedItemCount',
                      )
                    "
                    @focusin="
                      $root.onFocusIn('wrongPacketContentClaimedItemCount')
                    "
                    @focusout="
                      $root.onFocusOut('wrongPacketContentClaimedItemCount')
                    "
                  />
                </div>
              </div>
              <InputErrorComponent
                field="wrongPacketContentClaimedItemCount"
                :errors="$root.errors"
              />
            </div>
          </div>

          <template
            v-for="index in Array.from({
              length: $root.wrongPacketContentClaimedItemCount,
            }).map((u, i) => i)"
            :key="index"
          >
            <div class="row">
              <div class="col-md-12 col-lg-4">
                <label
                  :for="'wrongPacketContentClaimedPackageItemsLabel_' + index"
                  class="form-label"
                >
                  {{ $t("contentClaimedPackageItemLabel") }} *
                </label>
                <div
                  :class="
                    'input-wrap' +
                    ($root.wasFocused(
                      'wrongPacketContentClaimedPackageItemsLabel_' + index,
                    )
                      ? !$root.hasValue(
                          'wrongPacketContentClaimedPackageItemsLabel_' + index,
                        )
                        ? ' is-invalid'
                        : ' is-valid'
                      : '')
                  "
                >
                  <div class="input-shape">
                    <input
                      type="text"
                      :id="
                        'wrongPacketContentClaimedPackageItemsLabel_' + index
                      "
                      name="wrongPacketContentClaimedPackageItemsLabel[]"
                      required
                      :disabled="$root.formDisabledForce"
                      :class="
                        'form-control custom-mandatory-validation' +
                        ($root.wasFocused(
                          'wrongPacketContentClaimedPackageItemsLabel_' + index,
                        )
                          ? !$root.hasValue(
                              'wrongPacketContentClaimedPackageItemsLabel_' +
                                index,
                            )
                            ? ' is-invalid'
                            : ' is-valid'
                          : '')
                      "
                      @keyup="
                        $root.onKeyUp(
                          'wrongPacketContentClaimedPackageItemsLabel_' + index,
                        )
                      "
                      @focusin="
                        $root.onFocusIn(
                          'wrongPacketContentClaimedPackageItemsLabel_' + index,
                        )
                      "
                      @focusout="
                        $root.onFocusOut(
                          'wrongPacketContentClaimedPackageItemsLabel_' + index,
                        )
                      "
                    />
                  </div>
                </div>
                <InputErrorComponent
                  :field="'wrongPacketContentClaimedPackageItemsLabel_' + index"
                  :errors="$root.errors"
                />
              </div>

              <div class="col-md-12 col-lg-4">
                <label
                  :for="
                    'wrongPacketContentClaimedPackageItemsDescriptions_' + index
                  "
                  class="form-label"
                >
                  {{ $t("contentClaimedPackageItemDescription") }}
                </label>
                <div class="input-wrap">
                  <div class="input-shape">
                    <input
                      type="text"
                      :id="
                        'wrongPacketContentClaimedPackageItemsDescriptions_' +
                        index
                      "
                      :disabled="$root.formDisabledForce"
                      name="wrongPacketContentClaimedPackageItemsDescriptions[]"
                      class="form-control"
                      autocomplete="new-password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </fieldset>

        <fieldset
          v-if="
            ['client'].includes($root.appType) &&
            ['loss', 'damage', 'missing_content', 'substitution'].includes(
              $root.reason,
            ) &&
            $root.packetServiceType !== 'mk2c' &&
            $root.isContainsAdditionalFiles &&
            !$root.formDisabledForce
          "
          id="stepInvoiceIn"
          class="steps"
        >
          <h2 class="mb-2">
            {{ $t("navInvoiceIn") }}
            <span v-if="$root.isShowInvoiceInFile">*</span>
          </h2>
          <p>{{ $t("fileIsShowInvoiceText") }}</p>
          <SingleFileUploadComponent
            propField="invoiceInFileId"
            :errors="$root.errors"
            :propIsMandatory="
              ['client'].includes($root.appType) &&
              $root.isShowInvoiceInFile &&
              ['loss', 'damage', 'missing_content', 'substitution'].includes(
                $root.reason,
              ) &&
              !$root.formDisabledForce
            "
          />
        </fieldset>

        <fieldset
          v-if="
            ['client'].includes($root.appType) &&
            ['loss', 'damage', 'missing_content', 'substitution'].includes(
              $root.reason,
            ) &&
            $root.isContainsAdditionalFiles &&
            $root.packetServiceType !== 'mk2c' &&
            !$root.formDisabledForce
          "
          id="stepInvoiceOut"
          class="steps"
        >
          <h2 class="mb-2">
            {{ $t("navInvoiceOut") }}
            <span v-if="$root.isShowInvoiceInFile">*</span>
          </h2>
          <p>{{ $t("fileFastenYourRequestText") }}</p>
          <SingleFileUploadComponent
            propField="invoiceOutFileId"
            :errors="$root.errors"
            :propIsMandatory="
              ['client'].includes($root.appType) &&
              $root.isShowInvoiceInFile &&
              ['loss', 'damage', 'missing_content', 'substitution'].includes(
                $root.reason,
              ) &&
              !$root.formDisabledForce
            "
          />
        </fieldset>

        <fieldset
          v-if="
            ['client'].includes($root.appType) &&
            ['loss'].includes($root.reason) &&
            $root.isContainsAdditionalFiles &&
            $root.isDeliveryNoteFile &&
            !$root.formDisabledForce
          "
          id="stepDeliveryNote"
          class="steps"
        >
          <h2 class="mb-2">{{ $t("navDeliveryNote") }}</h2>
          <p>{{ $t("fileIsDeliveryNoteText") }}</p>
          <SingleFileUploadComponent
            propField="deliveryNoteFileId"
            :errors="$root.errors"
            :propIsMandatory="false"
          />
        </fieldset>

        <fieldset id="stepAttachments" class="steps">
          <h2 class="mb-2">
            <span
              v-if="
                ['client'].includes($root.appType) &&
                $root.isShowInvoiceInFile &&
                ['loss', 'damage', 'missing_content', 'substitution'].includes(
                  $root.reason,
                ) &&
                $root.isContainsAdditionalFiles &&
                !$root.formDisabledForce
              "
              >{{ $t("navAttachmentsOther") }}</span
            >
            <span v-else>{{ $t("navAttachments") }}</span>
            <span
              v-if="
                ['mobile', 'recipient'].includes($root.appType) &&
                ['damage', 'missing_content', 'substitution'].includes(
                  $root.reason,
                )
              "
              >*</span
            >
          </h2>
          <p
            v-if="
              ['client', 'mobile', 'recipient'].includes($root.appType) &&
              ['damage', 'missing_content', 'substitution'].includes(
                $root.reason,
              ) &&
              !$root.formDisabledForce
            "
          >
            {{ $t("filePhotoText") }}
          </p>
          <MultiFileUploadComponent :errors="$root.errors" />
        </fieldset>

        <fieldset
          id="clientNo"
          class="steps"
          v-if="['client'].includes($root.appType)"
        >
          <h2 class="mb-3">{{ $t("clientNo") }}</h2>
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('clientNo')
                    ? $root.hasError('clientNo')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <input
                    type="text"
                    id="clientNo"
                    name="clientNo"
                    maxlength="50"
                    v-model="$root.clientNo"
                    :class="
                      'form-control' +
                      ($root.wasFocused('clientNo')
                        ? $root.hasError('clientNo')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    :disabled="$root.formDisabledForce"
                    @keyup="$root.onKeyUp('clientNo')"
                    @focusin="$root.onFocusIn('clientNo')"
                    @focusout="$root.onFocusOutPackageId('clientNo')"
                  />
                </div>
              </div>
              <InputErrorComponent field="note" :errors="$root.errors" />
            </div>
          </div>
        </fieldset>

        <fieldset id="stepNote" class="steps">
          <h2 class="mb-3">
            {{
              $root.reason && ["incorrect_billing"].includes($root.reason)
                ? $t("navClaimNoteIncorrectBilling")
                : $t("navClaimNote")
            }}
          </h2>

          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('note')
                    ? $root.hasError('note')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape input-shape-textarea mb-6">
                  <textarea
                    id="note"
                    name="note"
                    v-model="$root.note"
                    :class="
                      'form-control' +
                      ($root.wasFocused('note')
                        ? $root.hasError('note')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    :disabled="$root.formDisabledForce"
                    :required="
                      $root.reason &&
                      ['incorrect_billing'].includes($root.reason)
                    "
                    @keyup="$root.onKeyUp('note')"
                    @focusin="$root.onFocusIn('note')"
                    @focusout="$root.onFocusOut('note')"
                  />
                </div>
              </div>
              <InputErrorComponent field="note" :errors="$root.errors" />
            </div>
          </div>
        </fieldset>

        <AffidavitComponent></AffidavitComponent>

        <div class="row" v-if="!$root.formDisabledForce">
          <div class="col-md-12 col-lg-8">
            <p>
              {{ $t("acceptanceTermsAndConditions") }}
              <a
                :href="$root.termsAndConditionsLinks[$root.country].acceptance"
                :title="$t('acceptanceTermsAndConditionsLinkTitle')"
                target="_blank"
              >
                {{ $t("acceptanceTermsAndConditionsLinkTitle") }} </a
              >.
            </p>
          </div>
        </div>

        <div class="row" v-if="!$root.formDisabledForce">
          <div class="col mt-4 mb-6">
            <div class="input-group mb-2">
              <div class="input-wrap">
                <div class="btn-shape btn-shape-red">
                  <input
                    type="submit"
                    class="btn btn-submit"
                    v-bind:value="$t('submitClaim')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<!-- ------------------------------------------------------------------------ TEMPLATE END ---------------- -->

<script>
import InputErrorComponent from "@/components/InputErrorComponent.vue"
import SingleFileUploadComponent from "@/components/SingleFileUploadComponent.vue"
import MultiFileUploadComponent from "@/components/MultiFileUploadComponent.vue"
import AffidavitComponent from "@/components/AffidavitComponent.vue"
import LanguageComponent from "@/components/LanguageComponent"
import { ALLOWED_COUNTRIES } from "@/constants/allowed_countries"

import $ from "jquery"
import "jquery-serializejson"
import { APP_CONFIG } from "@/constants/app_config"
import { API_CONFIG } from "@/constants/api_config"

export default {
  name: "ComplaintForm",
  components: {
    InputErrorComponent,
    SingleFileUploadComponent,
    MultiFileUploadComponent,
    AffidavitComponent,
    LanguageComponent,
  },
  data() {
    return {
      locale: this.$root.locale,
      maxFullLength: APP_CONFIG.max_full_length,
      maxClaimedItems: APP_CONFIG.max_claimed_items,
    }
  },
  methods: {
    /**
     * Action triggered on form submit.
     */
    onSubmit() {
      // AJAX send form
      let that = this
      let root = this.$root
      let url =
        API_CONFIG.api_base_url +
        API_CONFIG.create_complaint +
        "/" +
        root.appType +
        "/"
      let formData = $("form#complaintForm").serializeJSON()

      formData = that.transformClaimedPackageItems(formData)
      formData = that.transformWrongPacketContentClaimedPackageItems(formData)
      formData = that.transformFiles(formData)
      formData = that.transformPhone(formData)
      formData = that.transformCounts(formData)
      formData = that.transformPersonFrom(formData)

      if (
        root.appType === "mobile" &&
        root.reason &&
        ["loss", "damage", "missing_content", "substitution", "delay"].includes(
          root.reason,
        )
      ) {
        formData = that.transformAffidavit(formData)
      }

      if (root.isRequireBankInfo) {
        formData = that.transformBank(formData)
      }

      if (
        this.isDisplayedWarningUnder10DaysDelay(
          root.appType,
          formData.reason,
          formData.reasonDetail,
        )
      ) {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#reasonDelayDetailTenLess").offset().top - 16,
          },
          100,
        )
        return
      }

      if (!formData.itemCount) {
        delete formData.itemCount
      }

      $.ajax({
        url: url + this.$route.params.token,
        method: "POST",
        dataType: "json",
        data: JSON.stringify(formData),
        contentType: "application/json; charset=utf-8",
        headers: {
          "Content-Language": root.localeFull[root.$i18n.locale],
          Authorization: "Bearer " + API_CONFIG.bearer_token,
        },
        beforeSend: function () {
          root.appLoaded = false
          if (root.packageIdError) {
            root.setError("packageId", root.packageIdError)
            that.scrollToFirstError()
            return
          }
        },
        success: function (data) {
          // console.log("Success token data", data);
          if (data.success) {
            if (data.url) {
              window.location.href = data.url
              return
            } else {
              root.$router.push("/" + root.locale + "/form/finished")
              return
            }
          }

          root.$router.push("/" + root.$i18n.locale + "/not-allowed")
        },
        error: function (xhr) {
          if (xhr.status === 400) {
            let responseJSON = xhr.responseJSON

            if (responseJSON) {
              if (
                responseJSON.status &&
                responseJSON.status === "error" &&
                responseJSON.errors
              ) {
                root.markInvalidFields(responseJSON.errors)
                that.scrollToFirstError()
              } else {
                root.globalErrors = []
                root.globalErrors.push(root.$t("errMsgServerError"))
                root.showGlobalErrorModal()
              }
            } else {
              root.globalErrors = []
              root.globalErrors.push(root.$t("errMsgServerError"))
              root.showGlobalErrorModal()
            }
          } else {
            root.globalErrors = []
            root.globalErrors.push(root.$t("errMsgServerError"))
            root.showGlobalErrorModal()
          }
        },
        complete: function () {
          root.appLoaded = true

          root.markAllVisibleFields("complaintForm")
        },
      })
    },

    /**
     * Scroll page to the very first error.
     * There should be a small delay (100ms) to be sure that DOM is loaded properly.
     */
    scrollToFirstError() {
      setTimeout(() => {
        if ($(".is-invalid").length) {
          $("html, body").animate(
            {
              scrollTop: $(".is-invalid:first").offset().top - 50,
            },
            200,
          )
        }
      }, 100)
    },

    ajaxValidateToken() {
      let that = this
      let root = this.$root
      let url = API_CONFIG.api_base_url + API_CONFIG.validate_token + "/"
      $.ajax({
        url: url + this.$route.params.token,
        method: "GET",
        dataType: "json",
        headers: {
          "Content-Language": root.localeFull[root.$i18n.locale],
          Authorization: "Bearer " + API_CONFIG.bearer_token,
        },
        beforeSend: function () {
          root.appLoaded = false
        },
        success: function (data) {
          root.baseData = data

          root.appType = data.type
          root.country = ALLOWED_COUNTRIES.includes(data.country)
            ? data.country
            : "en"
          root.packageId = data.barcode
          root.reason = data.reason
          root.backupPackageId = data.barcode
          root.packetValue = data.packetValue
          root.packetCurrency = data.packetCurrency
          root.insuranceValue =
            data.insuranceValue + " " + data.insuranceCurrency
          root.insuranceCurrency = data.insuranceCurrency
          root.fullName = data.fullName
          root.packetServiceType = data.packetServiceType
          root.email = data.email
          root.phone = data.phone
          root.phonePrefix = data.phonePrefix

          root.enums.reasons = data.reasons
          root.enums.reasonDetails = data.reasonDetails
          root.enums.packageTypes = data.packageTypes
          root.enums.packageLinings = data.packageLinings
          root.enums.phonePrefixes = data.phonePrefixes

          if (data.bankCountries) {
            data.bankCountries.forEach(function (item) {
              root.enums.bankCountries[item.id] = item
            })
          }

          root.isShowInvoiceInFile = data.isShowInvoiceInFile
          root.isDeliveryNoteFile = data.isDeliveryNoteFile
          root.emailConfirmation = data.emailConfirmation

          root.isDelivered = data.isDelivered
          root.isLocalDelivery = data.isLocalDelivery
          root.isShowReasonDelay = data.isShowReasonDelay
          root.isRequireBankInfo = data.isRequireBankInfo
          root.isContainsAdditionalFiles = data.isContainsAdditionalFiles

          root.affidavitConsignedDate = data.consignedDate
          root.affidavitConsignedAddress = data.consignedAddress
          root.affidavitInsuranceValue =
            data.insuranceValue + " " + root.insuranceCurrency

          root.formDisabledForce = !!data.data
          root.existingComplaintData = data.data || []

          // update phone depending on phone prefix
          root.phone = root.preparePhone(root.phone, root.phonePrefix)

          /**
           * Override form data with already filled in complaint data.
           */
          if (root.formDisabledForce && root.existingComplaintData) {
            root.email = root.existingComplaintData.email
            root.emailConfirmation =
              root.existingComplaintData.emailConfirmation
            root.fullName = root.existingComplaintData.fullName
            root.packetServiceType =
              root.existingComplaintData.packetServiceType
            root.phone = root.existingComplaintData.phone
            root.phonePrefix = root.existingComplaintData.phonePrefix
            root.packageType = root.existingComplaintData.packageType
            root.packageTypeOther = root.existingComplaintData.packageTypeOther
            root.packageLining = root.existingComplaintData.packageLining
            root.packageLiningOther =
              root.existingComplaintData.packageLiningOther
            root.itemCount = root.existingComplaintData.itemCount
            root.claimedItemCount = root.existingComplaintData.items.length
            root.wrongPacketContentClaimedItemCount =
              root.existingComplaintData.wrongPacketContentItems.length
            if (root.existingComplaintData.items) {
              // transform "items" into separated fields - label, url, description
              root.existingComplaintData.items.forEach((item, index) => {
                setTimeout(() => {
                  $("input#claimedPackageItemsLabel_" + index.toString()).val(
                    item.label,
                  )
                  $("input#claimedPackageItemsUrls_" + index.toString()).val(
                    item.url,
                  )
                  $(
                    "input#claimedPackageItemsDescriptions_" + index.toString(),
                  ).val(item.description)
                }, 500)
              })
            }

            if (root.existingComplaintData.wrongPacketContentItems) {
              root.existingComplaintData.wrongPacketContentItems.forEach(
                (item, index) => {
                  setTimeout(() => {
                    $(
                      "input#wrongPacketContentClaimedPackageItemsLabel_" +
                        index.toString(),
                    ).val(item.label)
                    $(
                      "input#wrongPacketContentClaimedPackageItemsUrls_" +
                        index.toString(),
                    ).val(item.url)
                    $(
                      "input#wrongPacketContentClaimedPackageItemsDescriptions_" +
                        index.toString(),
                    ).val(item.description)
                  }, 500)
                },
              )
            }

            root.existingComplaintFiles = root.existingComplaintData.files
            root.reason = root.existingComplaintData.reason
            root.reasonBarcode = root.existingComplaintData.reasonBarcode
            root.reasonDetail = root.existingComplaintData.reasonDetail
            root.note = root.existingComplaintData.note
            root.personFrom = root.existingComplaintData.personFrom
            root.bankBankAccount = root.existingComplaintData.bankBankAccount
            root.bankValue = root.existingComplaintData.bankValue
            root.bankCountry = root.existingComplaintData.bankCountry
          }

          // after token validation success, get validation rules (same as if submitting form)
          that.loadValidationRules()
        },
        error: function (xhr) {
          root.globalErrors = []
          if (xhr.status === 400) {
            let responseJSON = xhr.responseJSON
            if (responseJSON) {
              responseJSON.errors.forEach((error) => {
                root.globalErrors.push(error.text)
              })
            }
          }

          if (!(root.globalErrors || root.globalErrors.length)) {
            // no response from server, show "server error message"
            root.globalErrors.push(root.$t("errMsgServerError"))
          }

          that.$router.push("/" + root.$i18n.locale + "/not-allowed")
        },
        complete: function () {
          root.appLoaded = true
        },
      })
    },

    loadValidationRules() {
      // let that = this;
      let root = this.$root
      let url =
        API_CONFIG.api_base_url +
        API_CONFIG.create_complaint +
        "/" +
        root.appType +
        "/"
      $.ajax({
        url: url + this.$route.params.token,
        method: "GET",
        dataType: "json",
        // data: JSON.stringify(JSON.parse("{}")),
        headers: {
          "Content-Language": root.localeFull[root.$i18n.locale],
          Authorization: "Bearer " + API_CONFIG.bearer_token,
        },
        beforeSend: function () {
          root.appLoaded = false
        },
        success: function () {},
        error: function (xhr) {
          if (xhr.status === 400) {
            let responseJSON = xhr.responseJSON
            if (responseJSON) {
              root.markInvalidFields(responseJSON.errors)
            } else {
              root.globalErrors = []
              root.globalErrors.push(root.$t("errMsgServerError"))
              root.showGlobalErrorModal()
            }
          } else {
            root.globalErrors = []
            root.globalErrors.push(root.$t("errMsgServerError"))
            root.showGlobalErrorModal()
          }
        },
        complete: function () {
          root.appLoaded = true
        },
      })
    },

    /**
     * Transforms separated HTML data about claimed packages from arrays into the object.
     * @param formData
     * @return formData
     */
    transformClaimedPackageItems(formData) {
      let root = this.$root
      let labels = formData.claimedPackageItemsLabel
      let urls = formData.claimedPackageItemsUrls
      let descriptions = formData.claimedPackageItemsDescriptions
      let claimedPackageItems = []

      if (labels && labels.length) {
        labels.forEach((item, index) => {
          claimedPackageItems.push({
            label: item,
            url: urls[index],
            description:
              root.reason &&
              [
                "loss",
                "missing_content",
                "substitution",
                "delay",
                "incorrect_billing",
                "not_paid_cod",
              ].includes(root.reason)
                ? descriptions[index]
                : "",
          })
        })
      }
      delete formData.claimedPackageItemsLabel
      delete formData.claimedPackageItemsUrls
      delete formData.claimedPackageItemsDescriptions

      formData["items"] = claimedPackageItems

      return formData
    },

    /**
     * Transforms separated HTML data about wrongPacketContent claimed packages from arrays into the object.
     * @param formData
     * @return formData
     */
    transformWrongPacketContentClaimedPackageItems(formData) {
      let root = this.$root
      let wrongPacketContentLabels =
        formData.wrongPacketContentClaimedPackageItemsLabel
      let wrongPacketContentDescriptions =
        formData.wrongPacketContentClaimedPackageItemsDescriptions
      let wrongPacketContentClaimedPackageItems = []

      if (wrongPacketContentLabels && wrongPacketContentLabels.length) {
        wrongPacketContentLabels.forEach((item, index) => {
          wrongPacketContentClaimedPackageItems.push({
            label: item,
            description:
              root.reason && ["substitution"].includes(root.reason)
                ? wrongPacketContentDescriptions[index]
                : "",
          })
        })
      }
      delete formData.wrongPacketContentClaimedPackageItemsLabel
      delete formData.wrongPacketContentClaimedPackageItemsDescriptions

      formData["wrongPacketContentItems"] =
        wrongPacketContentClaimedPackageItems

      return formData
    },

    /**
     * Transforms affidavit HTML data.
     * @param formData
     * @return formData
     */
    transformAffidavit(formData) {
      formData["sign"] = {
        name: formData.affidavitName,
        surname: formData.affidavitSurname,
        value: formData.affidavitValue,
        bankAccount: formData.affidavitBankAccount,
        address: formData.affidavitAddress,
      }

      delete formData.affidavitName
      delete formData.affidavitSurname
      delete formData.affidavitValue
      delete formData.affidavitBankAccount
      delete formData.affidavitAddress

      return formData
    },

    /**
     * Transforms fileIds in files array of objects.
     * @param formData
     * @return formData
     */
    transformFiles(formData) {
      if (formData.fileIds && formData.fileIds !== "") {
        let fileArray = formData.fileIds.split(",")
        formData["files"] = []
        fileArray.forEach((fId) => {
          formData["files"].push({
            fileId: fId,
          })
        })
        delete formData.fileIds
      }
      return formData
    },

    transformPhone(formData) {
      let phone = formData.phone
      let root = this.$root

      if (phone && phone.length && root.phonePrefix) {
        formData.phone = "+" + root.phonePrefix + phone
      }

      return formData
    },

    transformCounts(formData) {
      formData["itemCount"] = String(
        Math.max(formData.itemCount || 0, formData.claimedItemCount || 0),
      )

      formData["wrongPacketContentItemCount"] = String(
        Math.max(
          formData.wrongPacketContentItemCount || 0,
          formData.wrongPacketContentClaimedItemCount || 0,
        ),
      )

      return formData
    },
    transformPersonFrom(formData) {
      let root = this.$root

      if (
        !formData.personFrom ||
        !["recipient", "mobile"].includes(root.appType)
      ) {
        return formData
      }

      let personFrom = formData.personFrom

      if (personFrom === "api") {
        if (root.baseData.fullName && root.baseData.fullName !== null) {
          formData.fullName = root.baseData.fullName
        }
        if (root.baseData.phone && root.baseData.phone !== null) {
          formData.phone = root.baseData.phone
        }
        if (root.baseData.email && root.baseData.email !== null) {
          formData.email = root.baseData.email
          formData.emailConfirmation = root.baseData.email
        }
      }

      delete formData.baseData

      return formData
    },
    /**
     * Transforms Bank
     * @param formData
     * @return formData
     */
    transformBank(formData) {
      formData["bank"] = {
        bankAccount: formData.bankBankAccount,
        value: formData.bankValue,
        country: formData.bankCountry,
      }

      delete formData.bankBankAccount
      delete formData.bankValue
      delete formData.bankCountry

      return formData
    },
    isDisplayedWarningUnder10DaysDelay(appType, reason, reasonDetail) {
      return (
        ["client", "mobile"].includes(appType) &&
        reason === "not_paid_cod" &&
        (reasonDetail === "less_10_days" || !reasonDetail)
      )
    },
  },
  mounted() {
    /**
     * Application is fully loaded.
     * Check if token param came otherwise redirect to NotAllowed component.
     */
    let root = this.$root
    root.globalErrors = []
    if (!this.$route.params.token) {
      // no token param in URL > redirect to NotAllowed component
      root.globalErrors.push(root.$t("errMsgNoToken"))
      this.$router.push("/" + root.$i18n.locale + "/not-allowed")
    } else {
      // AJAX validate token
      this.ajaxValidateToken()
    }
  },
}
</script>

<style lang="scss" scoped>
.fit-content {
  max-width: -webkit-fill-available;
}

.padding-select-disabled select:disabled {
  padding: 12px 16px !important;
}
</style>
