import { createRouter, createWebHistory } from "vue-router"

import Guidepost from "@/components/Guidepost"
import ComplaintForm from "@/components/ComplaintForm"
import ThankYouPage from "@/components/ThankYouPage"
import NotAllowed from "@/components/NotAllowed"
import SignPending from "@/components/SignPending"

const routes = [
  {
    path: "/:lang([a-z]{2})?",
    component: Guidepost,
  },
  {
    path: "/:lang([a-z]{2})?/form/:token(.*)",
    component: ComplaintForm,
  },
  {
    // "recipient" alias (dříve web)
    path: "/:lang([a-z]{2})?/recipient/:token(.*)",
    component: ComplaintForm,
  },
  {
    // "mobile" alias
    path: "/:lang([a-z]{2})?/mobile/:token(.*)",
    component: ComplaintForm,
  },
  {
    // "client" alias
    path: "/:lang([a-z]{2})?/client/:token(.*)",
    component: ComplaintForm,
  },
  {
    path: "/:lang([a-z]{2})?/form/finished",
    component: ThankYouPage,
    props: {
      propSuccess: true,
    },
  },
  {
    path: "/:lang([a-z]{2})?/form/failed",
    component: ThankYouPage,
    props: {
      propSuccess: false,
    },
  },
  {
    path: "/:lang([a-z]{2})?/recipient",
    component: Guidepost,
  },
  {
    path: "/:lang([a-z]{2})?/mobile",
    component: Guidepost,
  },
  {
    path: "/:lang([a-z]{2})?/marketplace",
    component: Guidepost,
  },
  {
    path: "/:lang([a-z]{2})?/form/sign-pending/:id([0-9]+)",
    component: SignPending,
    props: {},
  },
  {
    path: "/client",
    alias: "/cs/client",
    beforeEnter() {
      window.location.href = "https://client.packeta.com/cs/packets/list"
    },
  },
  {
    path: "/de/client",
    beforeEnter() {
      window.location.href = "https://client.packeta.com/de/packets/list"
    },
  },
  {
    path: "/en/client",
    beforeEnter() {
      window.location.href = "https://client.packeta.com/en/packets/list"
    },
  },
  {
    path: "/hu/client",
    beforeEnter() {
      window.location.href = "https://client.packeta.com/hu/packets/list"
    },
  },
  {
    path: "/ro/client",
    beforeEnter() {
      window.location.href = "https://client.packeta.com/ro/packets/list"
    },
  },
  {
    path: "/pl/client",
    beforeEnter() {
      window.location.href = "https://client.packeta.com/pl/packets/list"
    },
  },
  {
    path: "/sk/client",
    beforeEnter() {
      window.location.href = "https://client.packeta.com/sk/packets/list"
    },
  },
  {
    path: "/:lang([a-z]{2})?/not-allowed",
    component: NotAllowed,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/not-allowed",
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
