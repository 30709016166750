/* API config */
export const API_CONFIG = {
  base_url: window.env.VUE_APP_BASE_URL,
  api_url: window.env.VUE_APP_API_BASE_URL,
  version: "v1",
  get api_base_url() {
    return [this.api_url, this.version].join("/") + "/"
  },
  create_token: "auth/token/create",
  capture_token: "auth/token/capture",
  validate_token: "form/token/info",
  file_upload: "form/file",
  create_complaint: "form/complaint",
  load_validation_rules: "form/complaint",
  is_signed: "form/complaint/sign",
  bearer_token: window.env.VUE_APP_BEARER_TOKEN,
}
