<template>
  <template v-for="error in errors" v-bind:key="error.field.name">
    <div v-if="error.field.name === field" class="invalid-feedback">
      <div v-html="error.field.message" />
    </div>
  </template>
</template>

<script>
export default {
  name: "InputErrorComponent",
  props: {
    field: String,
    errors: Array,
  },
}
</script>

<style lang="scss" scoped>
.invalid-feedback div a {
  font-size: 14px !important;
  color: #dc3545 !important;
}
</style>
