<!-- ------------------------------------------------------------------------ TEMPLATE START -------------- -->
<template>
  <div>
    <div class="row">
      <div class="col w-100">
        <div
          class="progress w-100 mt-3 ms-3"
          v-if="uploadProgress >= 0 && uploadProgress < 100"
        >
          <div
            class="progress-bar progress-bar-striped progress-bar-animated bg-success"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            :aria-valuenow="uploadProgress"
            :style="
              'width:' + (uploadProgress > 10 ? uploadProgress : 10) + '%'
            "
          >
            {{ $t("fileProgressUploading") }}
          </div>
        </div>
        <!--      <div v-else-if="uploadProgress >= 100">-->
        <!--        <div-->
        <!--          class="alert alert-success alert-dismissible fade show mt-3"-->
        <!--          role="alert"-->
        <!--        >-->
        <!--          <span>{{ $t('filesUploaded') }}</span>-->
        <!--          <button-->
        <!--            type="button"-->
        <!--            class="btn-close"-->
        <!--            data-bs-dismiss="alert"-->
        <!--            aria-label="Close"-->
        <!--          ></button>-->
        <!--        </div>-->
        <!--      </div>-->
      </div>
    </div>

    <div class="row">
      <div class="col mt-4">
        <div v-if="!$root.formDisabledForce">
          <!-- possible to upload files (form is not disabled) -->
          <div v-for="(file, index) in uploadList" v-bind:key="file">
            <div class="input-group mb-2">
              <!--          <div class="input-wrap">-->
              <!--            <div class="btn-shape btn-shape-red">-->
              <!--              <button-->
              <!--                type="button"-->
              <!--                class="btn btn-custom-red"-->
              <!--                :title="$t('btnRename')"-->
              <!--                @click="renameBtn(index)"-->
              <!--              >-->
              <!--                {{ $t("btnRename") }}-->
              <!--              </button>-->
              <!--            </div>-->
              <!--          </div>-->
              <div class="input-wrap mb-3">
                <div class="btn-shape btn-shape-white ms-2">
                  <!-- eslint-disable -->
                  <button
                    type="button"
                    class="btn btn-custom-white"
                    :title="$t('btnDelete')"
                    @click="deleteBtn(index)"
                  >
                    <svg
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.3182 2.66666H12.9545H10.9091V0.666656C10.9091 0.298656 10.6043 0 10.2273 0H4.77273C4.39569 0 4.09092 0.298656 4.09092 0.666656V2.66666H2.04546H0.681809C0.304774 2.66666 0 2.96531 0 3.33331C0 3.70131 0.304742 4 0.681809 4H1.36362V14C1.36362 15.1027 2.28136 16 3.40908 16H11.5909C12.7186 16 13.6364 15.1027 13.6364 14V4H14.3182C14.6952 4 15 3.70134 15 3.33334C15 2.96534 14.6953 2.66666 14.3182 2.66666ZM5.45454 1.33334H9.54546V2.66669H5.45454V1.33334ZM12.2727 14C12.2727 14.3673 11.9673 14.6667 11.5909 14.6667H3.40908C3.03271 14.6667 2.72727 14.3673 2.72727 14V4H4.77273H10.2273H12.2728V14H12.2727Z"
                        fill="#666666"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <span>
                <span class="text-filename" :data-index="index">
                  {{ file.file_obj.name }}
                </span>
                <div class="input-wrap mb-0 ms-3 d-none input-wrap-hidden">
                  <div class="input-shape">
                    <input
                      type="hidden"
                      class="form-control form-control-filename"
                      autocomplete="new-password"
                      :name="'filename[' + index + ']'"
                      :value="file.file_obj.name"
                      :data-filename="
                        file.file_obj.name.split('.').slice(0, -1).join('.')
                      "
                      :data-extension="file.file_obj.name.split('.').pop()"
                      @keyup="renameFile(index)"
                      @keydown="disableEnterKey"
                      @focusout="saveNewFilename(index)"
                    />
                  </div>
                </div>
              </span>
              <!-- uploaded file ids -->
              <input
                type="hidden"
                id="fileIds"
                name="fileIds"
                autocomplete="new-password"
                :value="fileIdsArray.join(',')"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <!-- form is disabled: list files from already existing complaint -->
          <div
            v-for="(file, index) in $root.existingComplaintFiles"
            v-bind:key="file"
          >
            <div class="input-group mb-2">
              <div class="text-filename">
                <a
                  :id="'existingComplaintFiles_' + index"
                  :href="file.url"
                  target="_blank"
                >
                  {{ file.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="!$root.formDisabledForce">
      <div v-if="errorUploadList.length" class="error-upload-list">
        <div
          v-for="error in errorUploadList"
          v-bind:key="error"
          class="alert alert-danger alert-dismissible fade show mb-3"
          role="alert"
        >
          <span>{{ error }}</span>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>

    <div class="row" v-if="!$root.formDisabledForce">
      <div class="col-md-12 col-lg-8 mb-3">
        <div
          :class="
            'input-group has-validation input-multifiles' +
            ($root.wasFocused('attachments')
              ? $root.hasError('attachments') &&
                ($root.noAttachments || $root.fileError) &&
                ['damage', 'missing_content', 'substitution'].includes(
                  $root.reason,
                ) &&
                ['mobile', 'recipient'].includes($root.appType)
                ? ' is-invalid'
                : ' is-valid'
              : '')
          "
        >
          <label for="attachments">
            <div
              :class="
                'input-wrap' +
                ($root.wasFocused('attachments')
                  ? $root.hasError('attachments') &&
                    ($root.noAttachments || $root.fileError) &&
                    ['damage', 'missing_content', 'substitution'].includes(
                      $root.reason,
                    ) &&
                    ['mobile', 'recipient'].includes($root.appType)
                    ? ' is-invalid'
                    : ' is-valid'
                  : '')
              "
            >
              <div class="btn-shape btn-shape-red-white">
                <button type="button" class="btn btn-custom-red-white">
                  {{ $t("btnChooseFileDelete") }}
                </button>
              </div>
            </div>
          </label>
          <div v-if="$root.noAttachments" class="file-no-selected">
            {{ $t("fAttachNoFileSelected") }}
          </div>

          <input
            type="file"
            id="attachments"
            name="attachments[]"
            multiple
            required
            autocomplete="new-password"
            :accept="validExtensions.join(',')"
            :class="
              'form-control' +
              ($root.wasFocused('attachments')
                ? $root.hasError('attachments') &&
                  ($root.noAttachments || $root.fileError) &&
                  ['damage', 'missing_content', 'substitution'].includes(
                    $root.reason,
                  ) &&
                  ['mobile', 'recipient'].includes($root.appType)
                  ? ' is-invalid'
                  : ' is-valid'
                : '')
            "
            @change="fileChange($event)"
            @focusin="$root.onFocusIn('attachments')"
            @focusout="$root.onFocusOut('attachments')"
          />
          <template
            v-if="
              ($root.noAttachments || $root.fileError) &&
              ['damage', 'missing_content', 'substitution'].includes(
                $root.reason,
              ) &&
              ['mobile', 'recipient'].includes($root.appType)
            "
          >
            <InputErrorComponent field="attachments" :errors="errors" />
            <InputErrorComponent field="files" :errors="errors" />
          </template>
        </div>
      </div>
    </div>

    <div class="row" v-if="!$root.formDisabledForce">
      <div class="col-md-12 col-lg-12 mb-3 mt-minus-3">
        <p
          v-html="$t('fAttachSupportedExtensions')"
          class="supported-extensions"
        ></p>
      </div>
    </div>
  </div>
</template>
<!-- ------------------------------------------------------------------------ TEMPLATE END ---------------- -->

<script>
import InputErrorComponent from "@/components/InputErrorComponent.vue"
import { API_CONFIG } from "@/constants/api_config"

import $ from "jquery"

export default {
  name: "MultiFileUploadComponent",
  components: {
    InputErrorComponent,
  },
  props: {
    field: String,
    errors: Array,
  },
  data() {
    return {
      uploadProgress: -1,
      fileIdsArray: this.fileIdsArray || [],
      uploadList: this.uploadList || [],
      errorUploadList: [],
      validExtensions: [".pdf", ".jpeg", ".jpg", ".png", ".tiff"],
    }
  },
  methods: {
    /**
     * Checks if there is at least one file.
     *
     * @param event
     */
    fileChange(event) {
      let root = this.$root
      let that = this
      let files = event.target.files
      let filesCount = files.length
      let alreadyExistingFilesCount = JSON.parse(
        JSON.stringify(that.uploadList),
      ).length
      that.fileIdsArray = []
      // that.uploadList = [];
      that.errorUploadList = []
      root.noAttachments = alreadyExistingFilesCount === 0 && filesCount === 0
      that.uploadProgress = 0
      let uploadProgressStep = 100 / filesCount

      if (filesCount > 0) {
        ;[...files].forEach((file, index) => {
          if (that.validateFileExtension(file)) {
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function () {
              let base64data = reader.result
              // remove first info about file type, leave just the pure base64 string
              base64data = base64data.split(",").slice(1).join(",")
              let ajaxFileData = {
                name: file.name,
                content: base64data.toString(),
              }

              $.ajax({
                type: "POST",
                url:
                  API_CONFIG.api_base_url +
                  API_CONFIG.file_upload +
                  "/" +
                  that.$route.params.token,
                contentType: false,
                processData: false,
                data: JSON.stringify(ajaxFileData),
                dataType: "json",
                async: false,
                headers: {
                  "Content-Language": root.localeFull[root.$i18n.locale],
                  Authorization: "Bearer " + API_CONFIG.bearer_token,
                },
                beforeSend: function () {
                  that.uploadProgress += uploadProgressStep
                },
                success: function (data) {
                  // console.log("--- SUCCESS ---", data);

                  if (data.fileId) {
                    that.uploadList.push({
                      index: index,
                      file_obj: file,
                      file_id: data.fileId,
                    })
                  } else {
                    that.errorUploadList.push(
                      root.$t("fileErrorNotUploaded") + ": " + file.name,
                    )
                  }

                  that.fileIdsArray = []
                  that.uploadList.forEach((f) => {
                    that.fileIdsArray.push(f.file_id)
                  })
                },
                error: function (xhr) {
                  if (xhr.status === 400) {
                    let responseJSON = xhr.responseJSON
                    if (responseJSON) {
                      if (responseJSON.errors) {
                        responseJSON.errors.forEach((error) => {
                          that.errorUploadList.push(
                            root.$t(
                              "fileError" +
                                error.code.charAt(0).toUpperCase() +
                                error.code.slice(1),
                            ) ||
                              root.$t("fileErrorNotUploaded") +
                                ": " +
                                file.name,
                          )
                          that.fileIdsArray = []
                          if (that.uploadList.length) {
                            that.uploadList.splice(index, 1)

                            that.uploadList.forEach((g) => {
                              that.fileIdsArray.push(g.file_id)
                            })
                          }
                        })
                      } else {
                        root.globalErrors = []
                        root.globalErrors.push(root.$t("errMsgServerError"))
                        root.showGlobalErrorModal()
                      }
                    } else {
                      root.globalErrors = []
                      root.globalErrors.push(root.$t("errMsgServerError"))
                      root.showGlobalErrorModal()
                    }
                  } else {
                    root.globalErrors = []
                    root.globalErrors.push(root.$t("errMsgServerError"))
                    root.showGlobalErrorModal()
                  }
                },
                complete: function () {},
              })
            }
          } else {
            if (!that.uploadList.length) {
              that.uploadProgress = -1
            } else {
              that.uploadProgress += uploadProgressStep
            }
          }
        })
      } else {
        if (alreadyExistingFilesCount === 0) {
          // no files
          root.noAttachments = true
        }
        that.uploadProgress = -1
      }

      event.target.value = ""
    },

    /**
     * Validate file extension
     *
     * @param file
     */
    validateFileExtension(file) {
      let that = this
      let extension = "." + file.name.split(".").pop().toLowerCase()
      if (that.validExtensions.indexOf(extension) === -1) {
        that.errorUploadList.push(
          this.$t("errMsgUnsupportedExtension") + ": " + file.name,
        )
        return false
      } else {
        return true
      }
    },

    /**
     * Action triggered on rename button click.
     *
     * @param index
     */
    renameBtn(index) {
      let input = $("input[name='filename[" + index + "]']")
      // change input type to "text"
      input.attr("type", "text")
      input.closest(".input-wrap").removeClass("d-none")
      input.closest(".input-wrap").show()
      // set input value to filename without an extension
      input.val(input.data("filename"))
      // hide text info
      $(".text-filename[data-index=" + index + "]").hide()
      // set focus on input
      input.focus()
    },

    /**
     * Action triggered on delete button click.
     *
     * @param index
     */
    deleteBtn(index) {
      let that = this
      let root = this.$root

      that.fileIdsArray = []
      if (that.uploadList.length) {
        that.uploadList.splice(index, 1)
        that.uploadList.forEach((f) => {
          that.fileIdsArray.push(f.file_id)
        })
      }

      // no files
      if (!that.uploadList.length) {
        root.noAttachments = true
        that.uploadProgress = -1
      }
    },

    disableEnterKey() {
      if (event.which === 13 || event.keyCode === 13) {
        event.preventDefault()
      }
    },

    /**
     * Rename file in FileList.
     *
     * @param index
     */
    renameFile(index) {
      let input = $("input[name='filename[" + index + "]']")
      let newValue = input.val()
      let filenameWithExtension = [newValue, input.data("extension")].join(".")
      // set data attribute filename
      input.data("filename", newValue)
      // set new filename with an extension
      $(".text-filename[data-index=" + index + "]").text(filenameWithExtension)
    },

    /**
     * Action triggered on input focus out.
     * Renames file by index.
     *
     * @param index
     */
    saveNewFilename(index) {
      let input = $("input[name='filename[" + index + "]']")
      let newValue = input.val()
      let filenameWithExtension = [newValue, input.data("extension")].join(".")
      // change input type back to "hidden"
      input.attr("type", "hidden")
      input.closest(".input-wrap").hide()
      // set data attribute filename
      input.data("filename", newValue)
      // set input value to filename with an extension
      input.val(filenameWithExtension)
      // show text info
      $(".text-filename[data-index='" + index + "']").show()
    },
  },
}
</script>
<!-- ------------------------------------------------------------------------ SCRIPT START ---------------- -->
<!-- ------------------------------------------------------------------------ SCRIPT END ------------------ -->

<!-- ------------------------------------------------------------------------ STYLES START ---------------- -->
<style lang="scss" scoped>
#app {
  .form-control-filename {
    padding: 12px 16px;
  }
  .input-wrap-hidden {
    min-width: 200px;
  }
  label[for="attachments"] {
    cursor: pointer;
  }
  input#attachments {
    opacity: 0;
    position: absolute;
    z-index: 5;
    width: 100%;
  }
  .file-no-selected {
    height: 50px;
    line-height: 50px;
    margin-left: 24px;
  }
  .supported-extensions {
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
<!-- ------------------------------------------------------------------------ STYLES END ------------------ -->
