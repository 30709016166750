export const TERMS_AND_CONDITIONS_LINKS = {
  cz: {
    general: "https://www.zasilkovna.cz/dokumenty-pro-partnery",
    generalMobile: "https://www.zasilkovna.cz/dokumenty-pro-zakazniky",
    acceptance: "https://www.zasilkovna.cz/dokumenty-pro-partnery",
  },
  de: {
    general: "https://packeta.de/agb/",
    generalMobile: "",
    acceptance: "https://files.packeta.com/web/files/DE_Wegnerpartner_IK.pdf",
  },
  en: {
    general:
      "https://files.packeta.com/web/files/general-terms-and-conditions.pdf",
    generalMobile: "https://www.zasilkovna.cz/en/documents-for-customers",
    acceptance:
      "https://files.packeta.com/web/files/Zasilkovna_Privacy-Policy.pdf",
  },
  hu: {
    general: "https://www.packeta.hu/dokumentumok-partnereknek",
    generalMobile: "https://www.packeta.hu/letoltheto-dokumentumok",
    acceptance: "https://www.packeta.hu/dokumentumok-partnereknek",
  },
  pl: {
    general: "https://przesylkownia.pl/eshop/regulaminy",
    generalMobile: "",
    acceptance:
      "https://files.packeta.com/web/files/Polityka%20Prywatnos%CC%81ci_GDPR_PL.pdf",
  },
  ro: {
    general: "https://www.packeta.ro/documente-parteneri",
    generalMobile: "",
    acceptance: "https://www.packeta.ro/documente-parteneri",
  },
  sk: {
    general: "https://www.packeta.sk/dokumenty-pre-podnikatelov",
    generalMobile: "https://www.packeta.sk/dokumenty-pre-zakaznikov",
    acceptance: "https://www.packeta.sk/dokumenty-pre-zakaznikov",
  },
}
