<!-- ------------------------------------------------------------------------ TEMPLATE START -------------- -->
<template>
  <div
    class="modal fade"
    id="globalError"
    tabindex="-1"
    aria-labelledby="globalErrorLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content bg-danger">
        <div class="modal-header">
          <h5 class="modal-title text-white">{{ $t("h1FormGlobalError") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t('close')"
          ></button>
        </div>
        <div class="modal-body">
          <div v-for="error in $root.globalErrors" :key="error">
            <p class="text-white">{{ error }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- ------------------------------------------------------------------------ TEMPLATE END ---------------- -->

<script>
export default {
  name: "GlobalErrorComponent",
  components: {},
  props: {},
  data() {
    return {}
  },
  methods: {},
}
</script>
<!-- ------------------------------------------------------------------------ SCRIPT START ---------------- -->
<!-- ------------------------------------------------------------------------ SCRIPT END ------------------ -->

<!-- ------------------------------------------------------------------------ STYLES START ---------------- -->
<style lang="scss" scoped></style>
<!-- ------------------------------------------------------------------------ STYLES END ------------------ -->
