export const TRANSLATIONS_MAPPER = {
  cs: "cs-CZ",
  de: "de-DE",
  en: "en-GB",
  hu: "hu-HU",
  pl: "pl-PL",
  ro: "ro-RO",
  sk: "sk-SK",
  xx: "xx-XX",
}
