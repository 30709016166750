<template>
  <section>
    <div class="container" v-if="!this.$route.query.isIframe">
      <div class="row">
        <div class="col">
          <img
            v-if="$root.locale !== 'cs'"
            v-bind:alt="$t('zasilkovna')"
            src="../public/images/logo-packeta.png"
          />
          <img
            v-else
            v-bind:alt="$t('zasilkovna')"
            src="../public/images/logo-zasilkovna.png"
          />
        </div>
      </div>
    </div>

    <router-view></router-view>

    <GlobalErrorComponent></GlobalErrorComponent>

    <div
      v-if="!appLoaded || redirecting"
      id="app-loader"
      class="d-flex justify-content-center text-danger"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ $t("appLoading") }}...</span>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery"
import { APP_CONFIG } from "@/constants/app_config"
import { ALLOWED_LANGS } from "@/constants/allowed_langs"
import { ALLOWED_COUNTRIES } from "@/constants/allowed_countries"
import { TERMS_AND_CONDITIONS_LINKS } from "@/constants/terms_and_conditions_links"
import GlobalErrorComponent from "@/components/GlobalErrorComponent"
import { Modal, Tooltip } from "bootstrap"

export default {
  name: "App",
  components: { GlobalErrorComponent },
  data() {
    return {
      languages: ALLOWED_LANGS,
      locale: this.$i18n.locale,
      localeFull: {
        cs: "cs_CZ",
        de: "de_DE",
        en: "en_GB",
        hu: "hu_HU",
        pl: "pl_PL",
        ro: "ro_RO",
        sk: "sk_SK",
      },
      country: ALLOWED_COUNTRIES.includes(this.country) ? this.country : "en",
      termsAndConditionsLinks: TERMS_AND_CONDITIONS_LINKS,
      missingFieldTranslations: ["Chybějící atribut: ", "Missing field: "],
      /**
       * Possible appType values
       *
       * client
       * - Právnická osoba
       * - Fyzická osoba podnikatel a Fyzická osoba s přístupem přes Klientskou sekci
       *
       * recipient (dříve web)
       * - Adresát (příjemce) zásilky
       *
       * mobile
       * - Fyzická osoba – používá mobilní aplikaci Zásilkovna
       *
       */
      baseData: this.baseData,

      appType: this.appType,
      appLoaded: this.appLoaded || false,
      redirecting: this.redirecting || false,
      isDelivered: this.isDelivered,
      isLocalDelivery: this.isLocalDelivery,
      isShowReasonDelay: this.isShowReasonDelay || false,
      packageIdError: this.packageIdError,
      isRequireBankInfo: this.isRequireBankInfo,
      isContainsAdditionalFiles: this.isContainsAdditionalFiles,
      bankBankAccount: this.bankBankAccount,
      bankValue: this.bankValue,
      bankCountry: this.bankCountry,

      formDisabledForce: this.formDisabledForce,
      existingComplaintData: this.existingComplaintData,
      existingComplaintFiles: this.existingComplaintFiles || [],

      errors: [],
      globalErrors: [],
      focusedElements: [],
      noAttachments: true,
      fileError: false,

      packageId: this.packageId,
      backupPackageId: this.backupPackageId,
      barcode: this.barcode,
      packetValue: this.packetValue,
      packetCurrency: this.packetCurrency,
      insuranceValue: this.insuranceValue,
      insuranceCurrency: this.insuranceCurrency,
      fullName: this.fullName,
      packetServiceType: this.packetServiceType,
      reason: this.reason,
      reasonDetail: this.reasonDetail,
      reasonBarcode: this.reasonBarcode,
      isShowInvoiceInFile: this.isShowInvoiceInFile,
      isDeliveryNoteFile: this.isDeliveryNoteFile,
      packageType: this.packageType,
      packageTypeOther: this.packageTypeOther,
      packageCustom: this.packageCustom,
      packageContent: this.packageContent,
      packageDamage: this.packageDamage,
      packageLining: this.packageLining,
      packageLiningOther: this.packageLiningOther,
      itemCount: this.itemCount || 1,
      claimedItemCount: this.claimedItemCount || 1,
      claimedPackageItemsLabel: this.claimedPackageItemsLabel,
      wrongPacketContentItemCount: this.wrongPacketContentItemCount || 1,
      wrongPacketContentClaimedItemCount:
        this.wrongPacketContentClaimedItemCount || 1,
      complaintCustom: this.complaintCustom,
      attachments: this.attachments,
      settlementCustom: this.settlementCustom,
      email: this.email,
      emailConfirmation: this.emailConfirmation,
      phone: this.phone,
      phonePrefix: this.phonePrefix,
      note: this.note,
      clientNo: this.clientNo,
      condition: this.condition,
      payloadMessage: this.payloadMessage,
      codeType: this.codeType,
      personFrom: this.personFrom,
      personType: this.personType,

      affidavitAddress: this.affidavitAddress,
      affidavitName: this.affidavitName,
      affidavitSurname: this.affidavitSurname,
      affidavitBankAccount: this.affidavitBankAccount,
      affidavitValue: this.affidavitValue,
      affidavitConsignedDate: this.affidavitConsignedDate,
      affidavitConsignedAddress: this.affidavitConsignedAddress,
      affidavitInsuranceValue: this.affidavitInsuranceValue,

      generalErrorValidationFields: [],
      mandatoryValidationFields: [],

      enums: {
        reasons: [],
        packageTypes: [],
        packageLinings: [],
        personFrom: { api: "api", form: "form" },
        bankCountries: {},
      },
    }
  },
  methods: {
    /* eslint-disable */
    /**
     * Action triggered on KeyUp/paste event when typing packageId.
     * Immediate fixing of packageId format "Z 123 4567 890".
     *
     * @param field
     */
    onKeyUpPackageId(field) {
      return this.realtimeTypingPackageFormat(field)
    },

    isMobileApp() {
      return (
        navigator.userAgent, navigator.userAgent.indexOf("C2C Packeta") >= 0
      )
    },

    /**
     * Action triggered on KeyUp/paste event for the barcodeReason field.
     *
     * @param field
     * @return {string|boolean}
     */
    onKeyUpReasonBarcode(field) {
      if (this[field] === this.packageId) {
        this.setError(field, this.$t("errMsgSubstitutionSameBarcodes"))
        return false
      }

      return this.realtimeTypingPackageFormat(field)
    },

    /**
     * Show global error modal.
     */
    showGlobalErrorModal() {
      let globalErrorModal = new Modal(document.getElementById("globalError"))
      globalErrorModal.show()
    },

    /**
     * Remove prefix from phone number.
     *
     * @param phone
     * @param prefix
     * @return {*}
     */
    preparePhone(phone, prefix) {
      let output = phone
      if (phone && phone.length && prefix && prefix.length) {
        let regex = new RegExp("^\\+" + prefix, "g")
        output = phone.replace(regex, "")
      }

      return output
    },

    /**
     * Realtime typing into packageId field to match the format "Z 123 4567 890"
     *
     * @param field
     * @return string
     */
    realtimeTypingPackageFormat(field) {
      this.removeError(field)

      let input = this[field]
      if (typeof input !== "undefined") {
        // remove unsupported characters
        input = input.replace(/[\W\s_]+/g, "")

        // add "Z" at the beginning of the string (if not set already)
        if (input[0] && input[0].toUpperCase() !== "Z") {
          input = "Z" + input
        }

        let split = 3
        let chunk = []

        // slice string to maximum size of 10 characters
        let maxLength = APP_CONFIG.max_raw_length + 1
        input = input.slice(0, Math.min(input.length, maxLength))

        // split by chunks in defined format
        for (let i = 0, len = input.length; i < len; i += split) {
          switch (i) {
            case 0:
              split = 1
              break
            case 1:
              split = 3
              break
            case 4:
              split = 4
              break
            case 7:
              split = 3
              break
          }
          chunk.push(input.substr(i, split))
        }

        // join with spaces
        this[field] = chunk.join(" ").toUpperCase()
      }

      this.validatePackageId(field)

      return this[field]
    },

    /**
     * @param field
     */
    onKeyUpEmail(field) {
      return this.validateEmail(field)
    },

    /**
     * @param field
     */
    onKeyUpEmailConfirmation(field) {
      return this.validateConfirmationEmail(field)
    },

    /**
     * @param field
     */
    onFocusOutEmail(field) {
      return this.validateEmail(field)
    },

    /**
     * @param field
     */
    onFocusOutEmailConfirmation(field) {
      return this.validateConfirmationEmail(field)
    },

    /**
     * Validate e-mail field.
     *
     * @param field
     */
    validateEmail(field) {
      this.removeError(field)

      if (!this[field]) {
        this.validateMandatory(field, this.$t("errMsgMandatory"))
      } else {
        if (!this.isValidEmail(this[field])) {
          this.setError(field, this.$t("errMsgWrongEmail"))
          return false
        }

        if (this[field] !== this["emailConfirmation"]) {
          this.setError(field, this.$t("errMsgEmailDoesNotMatch"))
          return false
        }

        this.removeError(field)
        this.removeError("emailConfirmation")
        return true
      }
    },

    /**
     * Validate confirmation e-mail field.
     *
     * @param field
     */
    validateConfirmationEmail(field) {
      this.removeError(field)

      if (!this[field]) {
        this.validateMandatory(field, this.$t("errMsgMandatory"))
      } else {
        if (!this.isValidEmail(this[field])) {
          this.setError(field, this.$t("errMsgWrongEmail"))
          return false
        }

        if (this[field] !== this["email"]) {
          this.setError(field, this.$t("errMsgEmailDoesNotMatch"))
          return false
        }

        this.removeError(field)
        this.removeError("email")
        return true
      }
    },

    /**
     * Return true if the e-mail is in valid format.
     *
     * @param email
     * @return {boolean}
     */
    isValidEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    /**
     * Action triggered on KeyUp event if specified for the field.
     * Currently checks if the field is valid.
     *
     * @param field
     */
    onKeyUp(field) {
      this.isFieldValid(field)
    },

    /**
     * Disable pressing "-", "+", "e" for number input fields.
     *
     * @param field
     */
    onKeyDownNumber(field) {
      let invalidChars = ["-", "+", "e", ",", "."]
      if (invalidChars.includes(event.key)) {
        event.preventDefault()
      }
      this.isFieldValid(field)
    },

    /**
     * Replace non-numeric characters for number input fields.
     *
     * @param field
     */
    onKeyUpNumber(field) {
      let input = this[field]
      if (typeof input !== "undefined") {
        // replace unsupported characters
        input = input
          .toString()
          .replace("e", "")
          .replace(",", "")
          .replace(".", "")
          .replace("/^[0-9]/g", "")

        this[field] = input
      }
      this.isFieldValid(field)
    },

    /**
     * Action triggered on FocusIn event if specified for the field.
     *
     * @param field
     */
    onFocusIn(field) {
      let root = this.$root
      let element = $("#" + field)
      let isMandatory = element.prop("required")
      let arrayIndex = root.mandatoryValidationFields.indexOf(field)

      if (isMandatory) {
        // push to mandatory fields if not exist
        if (arrayIndex === -1) {
          root.mandatoryValidationFields.push(field)
        }
      } else {
        // remove from mandatory fields if it exists
        if (arrayIndex >= 0) {
          root.mandatoryValidationFields.splice(arrayIndex, 1)
        }
      }

      element.closest(".input-wrap").addClass("active")
      this.focusedElements.push(field)
      this.isFieldValid(field)
    },

    /**
     * Action triggered on FocusIn event for the barcodeReason field.
     *
     * @param field
     * @return {boolean}
     */
    onFocusInReasonBarcode(field) {
      let element = $("#" + field)
      element.closest(".input-wrap").addClass("active")
      this.focusedElements.push(field)

      if (this[field] === this.packageId) {
        this.setError(field, this.$t("errMsgSubstitutionSameBarcodes"))
        return false
      }

      this.isFieldValid(field)
    },

    /**
     * Action triggered on FocusOut event if specified for the field.
     * Currently checks if the field is valid.
     *
     * @param field
     */
    onFocusOutPackageId(field) {
      $("#" + field)
        .closest(".input-wrap")
        .removeClass("active")
      // this.isFieldValid(field);
    },

    /**
     * Action triggered on FocusOut event if specified for the field.
     * Currently checks if the field is valid.
     *
     * @param field
     */
    onFocusOut(field) {
      $("#" + field)
        .closest(".input-wrap")
        .removeClass("active")
      this.isFieldValid(field)
    },

    onFocusOutReasonBarcode(field) {
      $("#" + field)
        .closest(".input-wrap")
        .removeClass("active")
      if (this[field] === this.packageId) {
        this.setError(field, this.$t("errMsgSubstitutionSameBarcodes"))
        return false
      }
      this.isFieldValid(field)
    },

    /**
     * Checks if the form field has already been focused.
     *
     * @param field
     */
    wasFocused(field) {
      return this.focusedElements.includes(field)
    },

    /**
     * Mark all visible fields in form.
     *
     * @param formId
     */
    markAllVisibleFields(formId) {
      let that = this
      $("form#" + formId)
        .find(":input:visible")
        .each((index, el) => {
          that.focusedElements.push($(el).attr("id"))
        })
    },

    /**
     * Process error of missing fields.
     *
     * @param field
     * @param parent
     * @param message
     */
    markMissingFields(field, parent = null, message = null) {
      let root = this.$root

      // custom fields replacement
      field = this.customFieldReplacement(field, parent)

      // push to mandatory fields if not exist
      if (root.mandatoryValidationFields.indexOf(field) === -1) {
        root.mandatoryValidationFields.push(field)
      }

      if (
        (!this[field] && !this.$root[field]) ||
        field === "reasonBarcode" ||
        (field === "claimedItemCount" &&
          (this[field] === "0" || this.$root[field] === "0"))
      ) {
        root.setError(field, message || this.$t("errMsgMandatory"))
      } else {
        root.removeError(field)
      }
    },

    /**
     * Process error of min length fields.
     *
     * @param field
     * @param parent
     * @param message
     */
    markMinLengthFields(field, parent = null, message = null) {
      let root = this.$root

      // custom fields replacement
      field = this.customFieldReplacement(field, parent)

      // push to mandatory fields if not exist
      if (root.mandatoryValidationFields.indexOf(field) === -1) {
        root.mandatoryValidationFields.push(field)
      }

      if (this[field].length < APP_CONFIG.affidavit_min_length) {
        root.setError(field, message || this.$t("errMsgMinLength"))
      } else {
        root.removeError(field)
      }
    },

    /**
     * Process error of wrong bank account format.
     *
     * @param field
     * @param parent
     * @param message
     */
    markBankAccountFields(field, parent = null, message = null) {
      let root = this.$root

      // custom fields replacement
      field = this.customFieldReplacement(field, parent)

      // push to mandatory fields if not exist
      if (root.mandatoryValidationFields.indexOf(field) === -1) {
        root.mandatoryValidationFields.push(field)
      }

      root.setError(field, message || this.$t("errMsgWrongBankAccount"))
    },

    /**
     * Process error of wrong bank account format.
     *
     * @param field
     * @param parent
     * @param message
     */
    markDecimalFields(field, parent = null, message = null) {
      let root = this.$root

      // custom fields replacement
      field = this.customFieldReplacement(field, parent)

      // push to mandatory fields if not exist
      if (root.mandatoryValidationFields.indexOf(field) === -1) {
        root.mandatoryValidationFields.push(field)
      }

      root.setError(field, message || this.$t("errMsgWrongDecimalInvalid"))
    },

    /**
     * Replace or transform field based on backend validation.
     *
     * @param field
     * @param parent
     *
     * @return field
     */
    customFieldReplacement(field, parent = null) {
      // files >>> attachments
      if (field === "files") {
        field = "attachments"
      }

      if (field === "invoiceIn") {
        field = "invoiceInFileId_file"
      }

      if (field === "invoiceOut") {
        field = "invoiceOutFileId_file"
      }

      // address >>> affidavitAddress
      if (field === "address") {
        field = "affidavitAddress"
      }

      // name >>> affidavitName
      if (field === "name") {
        field = "affidavitName"
      }

      // surname >>> affidavitSurname
      if (field === "surname") {
        field = "affidavitSurname"
      }

      // value >>> affidavitValue
      if (field === "value") {
        field = "affidavitValue"
      }

      // bankAccount >>> affidavitBankAccount
      if (field === "bankAccount") {
        field = "affidavitBankAccount"
      }

      // label >>> claimedPackageItemsLabel_#ID#
      if (field === "label") {
        let itemId = parent.replace(/\D+/g, "")
        if (itemId) {
          field = "claimedPackageItemsLabel_" + itemId
        }
      }

      return field
    },

    /**
     * Process general field errors.
     *
     * @param field
     * @param message
     */
    markGeneralErrorFields(field, message = null) {
      let root = this.$root
      let isOK = true
      // push to general error fields if not exist
      if (root.generalErrorValidationFields.indexOf(field) === -1) {
        isOK = false
      }

      if (!isOK) {
        root.setError(field, message || this.$t("errMsgGeneralError"))
      } else {
        root.removeError(field)
      }
    },

    /**
     * Mark all invalid fields by error category based on returned values from backend.
     *
     * @param errors
     */
    markInvalidFields(errors) {
      let that = this
      this.fileError = false

      errors.forEach((error) => {
        switch (error.code) {
          case APP_CONFIG.err_codes.missing_field:
          case APP_CONFIG.err_codes.not_found:
          case APP_CONFIG.err_codes.not_blank:
            if (
              that.missingFieldTranslations.some((substr) =>
                error.text.toLowerCase().startsWith(substr.toLowerCase()),
              )
            ) {
              error.text = this.$t("errMsgMandatory")
            }
            this.markMissingFields(error.field, error.parent, error.text)
            break
          case APP_CONFIG.err_codes.min_length_invalid:
            this.markMinLengthFields(error.field, error.parent, error.text)
            break
          case APP_CONFIG.err_codes.max_size_files:
            this.fileError = true
            this.markMissingFields(error.field, error.parent, error.text)
            break
          case APP_CONFIG.err_codes.type_decimal_invalid:
            this.markDecimalFields(error.field, error.parent, error.text)
            break
          case APP_CONFIG.err_codes.bank_account_invalid:
            this.markBankAccountFields(error.field, error.parent, error.text)
            break
          default:
            this.markGeneralErrorFields(error.field, error.text)
        }
      })
    },

    /**
     * Checks if the field has any error.
     *
     * @param field
     */
    hasError(field) {
      if (field) {
        return this.errors.find((f) => {
          return field === f.field.name
        })
      } else {
        return true
      }
    },

    /**
     * Checks if the field has a value (used for dynamic generated mandatory fields without connection to v-model).
     *
     * @param field
     */
    hasValue(field) {
      if (field) {
        let value = $("#" + field).val()
        return !!(value && value.length)
      } else {
        return true
      }
    },

    /**
     * Set a new or the latest error message on the form field.
     *
     * @param field
     * @param message
     */
    setError(field, message) {
      let indexAlreadyInArray = this.errors.findIndex((f) => {
        return field === f.field.name
      })
      if (indexAlreadyInArray === -1) {
        this.errors.push({
          field: {
            name: field,
            message: message,
          },
        })
      } else {
        this.errors[indexAlreadyInArray] = {
          field: {
            name: field,
            message: message,
          },
        }
      }
    },

    /**
     * Remove error messages from the field.
     *
     * @param field
     */
    removeError(field) {
      let index = this.errors.findIndex((f) => {
        return field === f.field.name
      })
      if (index >= 0) {
        this.errors.splice(index, 1)
      }
    },

    /**
     * Checks if the field is valid.
     *
     * @param field
     * @param message
     */
    isFieldValid(field, message = null) {
      if (this.mandatoryValidationFields.includes(field)) {
        return this.validateMandatory(field)
      } else if (this.generalErrorValidationFields.includes(field)) {
        return this.validateGeneralError(field, message)
      } else {
        this.removeError(field)
      }
    },

    /**
     * Validate general error field if visible (not hidden due to dependency on another field).
     *
     * @param field
     * @param message
     * @returns {boolean}
     */
    validateGeneralError(field, message = null) {
      let that = this
      let fieldExists = $("#" + field).length
      if (fieldExists) {
        that.setError(field, message)
        return false
      } else {
        that.removeError(field)
        return true
      }
    },

    /**
     * Validate mandatory field if visible (not hidden due to dependency on another field).
     *
     * @param field
     * @param message
     * @returns {boolean}
     */
    validateMandatory(field, message = null) {
      let that = this
      let fieldExists = $("#" + field).length
      if (fieldExists && !that[field]) {
        // field IS NOT valid, push error message to that.errors
        that.setError(field, message || this.$t("errMsgMandatory"))
        return false
      } else {
        // field IS valid, remove error message from that.errors
        that.removeError(field)
        return true
      }
    },

    /**
     * Validate package field on FocusOut.
     *
     * @param field
     * @returns {boolean}
     */
    validatePackageId(field) {
      if (!this[field] && field !== "reasonBarcode") {
        this.validateMandatory(field, this.$t("errMsgMandatoryPackageId"))
      } else {
        if (
          !this[field].match(/Z{1} \d{3} \d{4} \d{3}/g) &&
          ((this[field].length > 0 && field === "reasonBarcode") ||
            field !== "reasonBarcode")
        ) {
          // let fieldText = $('label[for="' + field + '"]').text();
          this.setError(field, this.$t("errMsgWrongPackageId"))
          return false
        } else {
          this.removeError(field)
          return true
        }
      }
    },

    /**
     * Update parameters in URL with the new ones without redirecting.
     *
     * @param newParams
     */
    updatePathParams(newParams) {
      const currentParams = this.$route.params
      const mergedParams = { ...currentParams, ...newParams }
      this.$router.push({ params: mergedParams, replace: true })
    },

    /**
     * Update language in URL with the new ones without redirecting.
     *
     * @param newLang
     */
    updateLangParam(newLang) {
      const currentParams = this.$route.params
      const mergedParams = { ...currentParams, ...{ lang: newLang } }
      this.$router.push({ params: mergedParams, replace: true })
    },

    /**
     * Initialize bootstrap tooltips.
     */
    initTooltips() {
      let tooltipTriggerList = [].slice.call(
        document.querySelectorAll("[data-bs-toggle=tooltip]"),
      )
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        new Tooltip(tooltipTriggerEl)
      })
    },

    initValues() {
      if (this.personFrom === undefined) {
        this.personFrom = this.enums.personFrom.api
      }
    },

    transformBarcode(barcode) {
      return barcode.replace("Z", "").replace(/\s/g, "").toString()
    },
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val
      this.updatePathParams({ lang: val })
    },

    itemCount(val) {
      if (val > APP_CONFIG.max_claimed_items) {
        this.itemCount = APP_CONFIG.max_claimed_items
      }
      if (parseInt(val) <= parseInt(this.claimedItemCount)) {
        this.claimedItemCount = val
      }
    },

    claimedItemCount(val) {
      if (val > APP_CONFIG.max_claimed_items) {
        this.claimedItemCount = APP_CONFIG.max_claimed_items
      }
      if (
        parseInt(val) >= parseInt(this.itemCount) &&
        !["substitution"].includes(this.reason)
      ) {
        this.claimedItemCount = this.itemCount
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.initTooltips()
      this.initValues()
    }, 500)
  },
}
</script>

<style lang="scss">
body {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  margin: 0;
  font-size: 1rem;
  background-color: $color-white;
  color: $color-grey;
}

.mt-6 {
  margin-top: 4.5rem !important;
}
.mt-7 {
  margin-top: 6rem !important;
}
.mt-8 {
  margin-top: 7.5rem !important;
}
.mt-minus-3 {
  margin-top: -1.5rem !important;
}

.mb-6 {
  margin-bottom: 4.5rem !important;
}
.mb-7 {
  margin-bottom: 6rem !important;
}
.mb-8 {
  margin-bottom: 7.5rem !important;
}

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-grey;
  overflow-x: hidden;

  .ubuntu {
    font-family: "Ubuntu";
    font-weight: normal;
  }
  .ubuntu-bold {
    font-family: "Ubuntu";
    font-weight: bold;
  }
  .roboto-regular {
    font-family: Roboto, sans-serif;
    font-weight: 400;
  }
  .roboto-medium {
    font-family: Roboto, sans-serif;
    font-weight: 500;
  }

  h1 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: normal;
    font-size: 3.5rem;
    line-height: 4rem;
    color: $color-black;
  }
  h2 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: $ubuntu-semibold-weight;
    font-size: 2rem;
    line-height: 2.5rem;
  }
  h3 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  a {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: $color-dark-red;
  }
  ul {
    list-style: none;
    li {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $color-light-grey;

      &::before {
        display: inline-block;
        color: $color-dark-red;
        content: "\0025AA";
        margin-left: -1em;
        margin-right: 0.7em;
      }
    }
  }
  fieldset {
    margin-bottom: 4rem;
  }
  label {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $color-label;

    a {
      font-size: 14px;
      line-height: 18px;
    }
  }
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
  textarea,
  select {
    position: relative;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: $color-black;

    padding: 16px;

    border: 1px solid $color-border;
    border-radius: 0;

    margin-bottom: 3rem;
  }
  input[readonly] {
    background-color: $color-disabled;
  }
  ::placeholder {
    // Chrome, Firefox, Opera, Safari 10.1+
    color: $color-placeholder;
    opacity: 1; // Firefox
  }

  :-ms-input-placeholder {
    // Internet Explorer 10-11
    color: $color-placeholder;
  }

  ::-ms-input-placeholder {
    // Microsoft Edge
    color: $color-placeholder;
  }

  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
  textarea,
  select {
    &.is-invalid {
      border-color: $color-dark-red;
    }
  }

  .invalid-feedback {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    position: relative;
    margin-top: -3rem; // fill the space of .input-wrap
    margin-bottom: 3rem;
    padding-top: 2px;
  }

  .input-multifiles,
  .input-singlefile {
    &.is-invalid {
      padding-bottom: 48px;
      .input-wrap.is-invalid {
        margin-bottom: 0;
      }
    }

    .invalid-feedback {
      display: flex;
      margin-top: 16px;
    }
  }

  .form-check {
    .invalid-feedback {
      margin-top: 0;
    }
  }

  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
  textarea,
  select,
  button {
    box-shadow: none;

    &:focus {
      box-shadow: none !important;
    }
  }

  .input-wrap {
    margin-bottom: 3rem; // keep a space for error message element .invalid-feedback

    &.active {
      filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.1));
    }

    .input-shape {
      position: relative;
      background: $color-border;
      color: $color-black;
      width: 100%;
      height: 50px;
      line-height: 50px;
      -webkit-clip-path: polygon(
        6px 0,
        100% 0,
        100% calc(100% - 6px),
        calc(100% - 4px) 100%,
        0 100%,
        0 6px
      );
      clip-path: polygon(
        6px 0,
        100% 0,
        100% calc(100% - 6px),
        calc(100% - 6px) 100%,
        0 100%,
        0 6px
      );
      z-index: 2;

      &:before {
        content: "";
        width: calc(100% - 2px);
        height: 48px;
        position: absolute;
        top: 1px;
        left: 1px;
        background: $color-white;
        -webkit-clip-path: polygon(
          6px 0,
          100% 0,
          100% calc(100% - 6px),
          calc(100% - 6px) 100%,
          0 100%,
          0 6px
        );
        clip-path: polygon(
          6px 0,
          100% 0,
          100% calc(100% - 6px),
          calc(100% - 6px) 100%,
          0 100%,
          0 6px
        );
        z-index: 3;
      }

      &.input-shape-textarea {
        height: 100px;

        &:before {
          height: 98px;
        }
      }

      input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
      textarea,
      select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
        background: none;
        border: none;

        &:focus {
          outline: none;
        }

        &:disabled {
          background: $color-disabled;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          top: 1px;
          left: 1px;
        }
      }

      select {
        // keep arrow for select
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        padding-right: 4.125rem;
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
      }

      textarea {
        resize: none;
      }

      .input-group-select {
        width: 68px;
        text-align: left;
        &.input-group-select--prefix {
          border-right: 1px solid $color-border;
          padding: 0 20px 0 10px;
          background-position: right 0.25rem center;
        }
      }
    }

    &.is-invalid {
      .input-shape {
        background: $color-dark-red;

        .input-group-select {
          border-color: $color-dark-red;
        }
      }
    }
  }

  // hover na input file button
  .input-group {
    &:hover {
      .input-wrap {
        .btn-shape-red-white {
          background: $color-dark-red-hover;
          &:before {
            background: $color-dark-red-hover;
          }
          .btn-custom-red-white {
            background: $color-dark-red-hover;
            color: $color-white;
          }
        }
      }
    }
  }

  .input-group-text {
    z-index: 4;
    width: 62px;
    height: 48px;
    top: 1px;
    left: 1px;
    position: absolute;
    border: none;
    border-radius: 0;
    border-right: 1px solid $color-border;
  }
  .input-group-input {
    margin-left: 60px;
  }

  .input-group-text-right {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #e8e8e8;
    padding: 0 15px;
    z-index: 5;
  }

  .input-radio {
    margin-bottom: 10px;

    input[type="radio"] {
      position: absolute;
      left: -9999px;

      & + label {
        position: relative;
        padding: 3px 0 0 40px;
        cursor: pointer;
        color: $color-label;
        font-size: 16px;

        &:before {
          content: "";
          background: $color-white;
          border: 1px solid $color-border;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:after {
          content: "";
          background: $color-black;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          top: 7px;
          left: 7px;
          opacity: 0;
          transform: scale(2);
          transition: transform 0.3s linear, opacity 0.3s linear;
        }
      }

      &:checked + label {
        color: $color-black;

        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  .btn-shape {
    position: relative;
    color: $color-black;
    height: 50px;
    line-height: 50px;
    -webkit-clip-path: polygon(
      6px 0,
      100% 0,
      100% calc(100% - 6px),
      calc(100% - 4px) 100%,
      0 100%,
      0 6px
    );
    clip-path: polygon(
      6px 0,
      100% 0,
      100% calc(100% - 6px),
      calc(100% - 6px) 100%,
      0 100%,
      0 6px
    );
    z-index: 2;

    &.btn-shape-white {
      background: $color-border;

      &:before {
        display: block;
        content: "";
        width: calc(100% - 2px);
        height: 48px;
        position: absolute;
        top: 1px;
        left: 1px;
        background: $color-white;
        -webkit-clip-path: polygon(
          6px 0,
          100% 0,
          100% calc(100% - 6px),
          calc(100% - 6px) 100%,
          0 100%,
          0 6px
        );
        clip-path: polygon(
          6px 0,
          100% 0,
          100% calc(100% - 6px),
          calc(100% - 6px) 100%,
          0 100%,
          0 6px
        );
        z-index: 3;
      }
    }

    &.btn-shape-red {
      background: $color-dark-red;
    }

    &.btn-shape-red-white {
      background: $color-dark-red;

      &:before {
        display: block;
        content: "";
        width: calc(100% - 2px);
        height: 48px;
        position: absolute;
        top: 1px;
        left: 1px;
        background: $color-white;
        -webkit-clip-path: polygon(
          6px 0,
          100% 0,
          100% calc(100% - 6px),
          calc(100% - 6px) 100%,
          0 100%,
          0 6px
        );
        clip-path: polygon(
          6px 0,
          100% 0,
          100% calc(100% - 6px),
          calc(100% - 6px) 100%,
          0 100%,
          0 6px
        );
        z-index: 3;
      }
    }
  }

  .btn-submit {
    background-color: $color-dark-red;
    color: $color-white;
    padding: 13px 33px;

    &:hover {
      background: $color-dark-red-hover;
    }
  }

  .btn-grey {
    background-color: $color-placeholder;
    color: $color-black;

    &:hover {
      background-color: $color-light-grey;
    }
  }

  .btn-custom-red {
    color: $color-white;
    padding: 10px 20px;
    z-index: 4;
  }

  .btn-custom-white {
    color: $color-light-grey;
    padding: 10px 20px;
    z-index: 4;
  }

  .btn-custom-red-white {
    color: $color-dark-red;
    padding: 10px 20px;
    z-index: 4;
  }

  .btn-custom-grey {
    color: $color-grey;
    padding: 10px 20px;
    z-index: 4;
  }

  a.btn {
    text-decoration: none;
  }

  .icon-exclamation-mark {
    display: inline-block;
    width: 50px;
    height: 20px;
    color: $color-dark-red;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: 0.65em 0.65em;
  }

  .text-filename {
    display: inline-block;
    color: $color-light-grey;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 44px;
    margin-left: 2em;
  }

  .subheader {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $color-dark-red;
  }

  div.info-mandatory-fields {
    display: block;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $color-label;
  }

  .modal-content {
    &.bg-danger {
      .modal-header {
        border-bottom: none;
      }
    }
  }
}

.top-info-message {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: $color-light-grey;
}

#app-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;

  .spinner-border {
    display: flex;
    margin: 0 auto;
    width: 5rem;
    height: 5rem;
  }
}

.box-notification-warning {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  padding: 0.5rem 0.7rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.75rem;
}
</style>
