<!-- ------------------------------------------------------------------------ TEMPLATE START -------------- -->
<template>
  <div>
    <LanguageComponent></LanguageComponent>

    <div
      v-if="$root.appLoaded"
      id="app-loader"
      class="d-flex justify-content-center text-danger flex-column"
    >
      <div class="spinner-border mb-5" role="status">
        <span class="visually-hidden">{{ $t("appLoading") }}...</span>
      </div>

      <div>
        <strong class="display-6">{{ $t("pleaseWaitVerifying") }}...</strong>
      </div>
    </div>
  </div>
</template>
<!-- ------------------------------------------------------------------------ TEMPLATE END ---------------- -->

<!-- ------------------------------------------------------------------------ SCRIPT START ---------------- -->
<script>
import LanguageComponent from "@/components/LanguageComponent"
import { API_CONFIG } from "@/constants/api_config"
import $ from "jquery"

export default {
  name: "SignPending",
  components: {
    LanguageComponent,
  },
  props: {},
  data() {
    return {}
  },
  methods: {
    checkIsSignedAndRedirect() {
      let root = this.$root
      let id = this.$route.params.id
      let url = API_CONFIG.api_base_url + API_CONFIG.is_signed + "/" + id

      $.ajax(url, {
        method: "GET",
        headers: {
          "Content-Language": root.localeFull[root.$i18n.locale],
          Authorization: "Bearer " + API_CONFIG.bearer_token,
        },
        success: function () {
          root.$router.push("/" + root.locale + "/form/finished")
        },
        error: function () {
          root.$router.push("/" + root.locale + "/form/failed")
        },
      })
    },
  },
  mounted() {
    this.$root.appLoaded = true

    this.checkIsSignedAndRedirect()
  },
}
</script>
<!-- ------------------------------------------------------------------------ SCRIPT END ------------------ -->

<!-- ------------------------------------------------------------------------ STYLES START ---------------- -->
<style lang="scss" scoped></style>
<!-- ------------------------------------------------------------------------ STYLES END ------------------ -->
