<!-- ------------------------------------------------------------------------ TEMPLATE START -------------- -->
<template>
  <div>
    <LanguageComponent></LanguageComponent>

    <div class="container">
      <div class="row">
        <div class="d-flex justify-content-center">
          <div id="fullPackageWrapper" class="mt-6">
            <img
              class="thank-you-package-img img-fluid"
              alt=""
              src="/images/finished-package.png"
            />
            <img
              v-if="success"
              alt=""
              class="icon-package-img"
              src="/images/check-package.png"
            />
            <img
              v-else
              alt=""
              class="icon-package-img"
              src="/images/failed-package.png"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="d-flex justify-content-center">
          <h1 v-if="success" class="mt-3">{{ $t("h1FormFinished") }}!</h1>
          <h1 v-else class="mt-3">{{ $t("h1FormFailed") }}!</h1>
        </div>
        <div class="d-flex justify-content-center">
          <h2 v-if="success" class="mt-3 mb-7">{{ $t("pFormFinished") }}</h2>
          <h2 v-else class="mt-3 mb-7">{{ $t("pFormFailed") }}</h2>
        </div>
      </div>
      <div
        v-if="this.$root.isMobileApp()"
        class="d-flex justify-content-center mb-7"
      >
        <a
          :href="'/' + this.$root.locale + '/form/dismiss'"
          class="btn btn-submit"
          >{{ $t("btmSuccessMobile") }}</a
        >
      </div>
    </div>
  </div>
</template>
<!-- ------------------------------------------------------------------------ TEMPLATE END ---------------- -->

<!-- ------------------------------------------------------------------------ SCRIPT START ---------------- -->
<script>
import LanguageComponent from "@/components/LanguageComponent"

export default {
  name: "ThankYouPage",
  components: {
    LanguageComponent,
  },
  props: {
    propSuccess: Boolean,
  },
  data() {
    return {
      success: this.propSuccess,
    }
  },
  mounted() {
    let root = this.$root
    root.appLoaded = true
  },
  methods: {},
}
</script>
<!-- ------------------------------------------------------------------------ SCRIPT END ------------------ -->

<!-- ------------------------------------------------------------------------ STYLES START ---------------- -->
<style lang="scss" scoped>
#app {
  #fullPackageWrapper {
    position: relative;
    width: 242px;
    height: 205px;

    .thank-you-package-img {
      position: absolute;
      z-index: 10;
      bottom: 0;
      right: 0;
    }
    .icon-package-img {
      position: absolute;
      bottom: 11px;
      right: 22px;
      z-index: 11;
    }
  }
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}
</style>
<!-- ------------------------------------------------------------------------ STYLES END ------------------ -->
